import React from "react";
import "semantic-ui-css/semantic.min.css";
import "../Login/Login.scss";
import { Image, Icon } from "semantic-ui-react";
import { withRouter } from "react-router-dom";

import "../../App.scss";

function PublicLike({
  content = "",
  why_content = "",
  content_type = "",
  media_url = "",
}) {
  const URL_REGEX =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
  const words = content.split(" ");
  const why_words = why_content.split(" ");
  return (
    <>
      <div className="why-parent-div">
        <div className="content">
          <p style={{color:'white'}}>
          {words.map((word) => {
            return word.match(URL_REGEX) ? (
              <>
                <a href={word} target="_blank" >
                  {word}
                </a>{" "}
              </>
            ) : (
              word + " "
            );
          })}
          </p>
          {content_type === "video" && (
            <div>
              <video style={{ width: "100%", maxHeight: "300px" }} controls>
                <source src={media_url} type="video/mp4" />
              </video>
            </div>
          )}
          {(content_type === "photo" ||
            content_type === "image" ||
            content_type === "gif") && (
            <div>
              <Image
                style={{ maxHeight: "300px", width: "100%" }}
                src={media_url}
              />
            </div>
          )}
          {content_type === "file" && (
            <div>
              <a href={media_url} download>
                <span
                  style={{ fontWeight: 600, marginRight: 10, color: "#fff" }}
                >
                  Document
                </span>
                <Icon
                  style={{ color: "#fff" }}
                  name="arrow alternate circle down"
                  size="large"
                />
              </a>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position:'sticky'
            }}
          >
            <div className="left-line"></div>
            <div className="why-word">Why?</div>
            <div className="right-line"></div>
          </div>
          <p style={{color:"white"}}>
          {why_words.map((word) => {
            return word.match(URL_REGEX) ? (
              <>
                <a href={word} target="_blank" >
                  {word}
                </a>{" "}
              </>
            ) : (
              word + " "
            );
          })}
          </p>
        </div>
      </div>
    </>
  );
}
export default withRouter(PublicLike);
