export const Paths = {
  Dashboard: "/dashboard",
  LogIn: "/",
  SignUp: "/sign-up",
  AboutUS: "/about-us",
  Cookies: "/cookies",
  ChangePassword:"/change-password",
  ActivateAccount:"/activate/:id/:token",
  ContactUS: "/contact-us",
  CommunityGuideLine: "/community-guidelines",
  PrivatePolicy: "/policy",
  Help: "/help",
  ForgotPassword: "/forgot-password",
  InviteFriend: "/invite-friend",
  PrivacySetting: "/privacy-setting",
  ResetPassword: "/reset-password",
  BlockedUser: "/block-userlist",
  ProfileSetUp: "/profile",
  Profile: "/user-profile",
  UserProfileDetail: "/user-profile/:id",
  PostDetails:"/post/:id",
  Favourite: "/favourite",
  Favourite_id: "/favourite/:id",
  ProfileSetting: "/profile-setting",
  Following: "/following",
  Follower: "/followers",
  FollowingOtherUserDetail: "/following/:id",
  FollowerOtherUserDetail: "/followers/:id",
  Friends: "/friends",
  CreatePost: "/create-post",
  NotificationSettings: "/notification-settings",
  Notifications: "/notifications",
  PublicReactions: "/public-reactions",
  UpdatePost: "/create-post/:id",
  UserChat: "/chat",
  TermCondition:"/terms-conditions",
  NewPassword: "/new-password"
};
