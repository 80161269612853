import React, { useState, useEffect } from "react";
import "semantic-ui-css/semantic.min.css";
// import "../../Login/Login.scss";
import { Button, Header, Form, Icon,} from "semantic-ui-react";
//import { Icon } from "semantic-ui-react";
import "../../../App.scss";
import Footer from "../Footer";
import "./about-us.scss";
import LogoHeader from "./LogoHeader";
import { UserProfileServices } from "../../../Services/UserProfileServices";
import { toast } from "react-toastify";

const userProfileServices = new UserProfileServices();

export default function ChangePassword(props) {
  const [Show, setShow] = useState(true);
  const [ShowConfirm, setShowConfirm] = useState(true);
  const [passwordError, setPasswordErr] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [changePassword, setchangePassword] = useState({
    old_password: "",
    new_password1: "",
    new_password2: "",
  });

  useEffect(() => {}, []);

  const handleChangePasswordDetails = (event) => {
    if (event.target.name === "new_password1") {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const passwordLength = event.target.value.trim().length;
      const uppercasePassword = uppercaseRegExp.test(event.target.value.trim());
      const lowercasePassword = lowercaseRegExp.test(event.target.value.trim());
      const digitsPassword = digitsRegExp.test(event.target.value.trim());
      const specialCharPassword = specialCharRegExp.test(
        event.target.value.trim()
      );
      const minLengthPassword = minLengthRegExp.test(event.target.value.trim());
      let errMsg = "";
      if (passwordLength === 0) {
        errMsg = "Password is empty";
      } else if (!uppercasePassword) {
        errMsg = "At least one Uppercase";
      } else if (!lowercasePassword) {
        errMsg = "At least one Lowercase";
      } else if (!digitsPassword) {
        errMsg = "At least one digit";
      } else if (!specialCharPassword) {
        errMsg = "At least one Special Characters";
      } else if (!minLengthPassword) {
        errMsg = "At least minumum 8 characters";
      } else {
        errMsg = "";
      }

      setPasswordErr(errMsg);
    }
    if (event.target.name === "new_password2") {
      if (changePassword.new_password1) {
        if (changePassword.new_password1 !== event.target.value.trim()) {
          setConfirmPasswordError("Confirm password is not matched");
        } else {
          setConfirmPasswordError("");
        }
      }
    }
    setchangePassword({
      ...changePassword,
      [event.target.name]:event.target.value
     })
  };

  const handleSubmit=()=>{
    userProfileServices.changeMyPassword(changePassword).then(data=>{
    
      toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      
    },
    (error)=>{
    
      toast.error(error.response.data.old_password[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    })

  }

  return (
    <>
      <div className="login-page" style={{ marginBottom: "20%" }}>
        <LogoHeader />
        <div
          className="main-content"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Form.Group widths="equal" style={{ width: "50%" }}>
            <Header as="h3">Change password</Header>
            <Form style={{ width: "100%" }}>
              <Form.Input
                label="Old password"
                fluid
                type="password"
                required
                style={{ marginTop: "10px" }}
                placeholder="Enter old password"
                name="old_password"
                onChange={handleChangePasswordDetails}
                value={changePassword.old_password}
              />
              <div>
              <Form.Input
                label="New Password"
                fluid
                required
                type={Show ? "password":"input"}
                style={{ marginTop: "10px" }}
                placeholder="Enter new password"
                name="new_password1"
                onChange={handleChangePasswordDetails}
                value={changePassword.new_password1}
                error={passwordError ? true:false}
              />
                <Icon
                  name={Show ? "eye slash outline" : "eye"}
                  onClick={() => setShow(!Show)}
                  className="eye-icon-crfpass2"
                  
                />
              <span style={{color:'red'}}>{passwordError}</span>
            
                </div>
              <Form.Input
                label="Confirm Password"
                fluid
                required
                type={ShowConfirm ? "password":"input"}
                style={{ marginTop: "10px" }}
                placeholder="Confirm new password"
                name="new_password2"
                onChange={handleChangePasswordDetails}
                value={changePassword.new_password2}
                error={confirmPasswordError ? true : false}
              />
                <Icon
                  name={ShowConfirm ? "eye slash outline" : "eye"}
                  onClick={() => setShowConfirm(!ShowConfirm)}
                  className="eye-icon-pass2"
                
                />
              <span style={{color:'red'}}>{confirmPasswordError}</span>
            
              <Button
                className="send-contact-us forgot-pwd-send"
                style={{
                  borderRadius: 4,
                  margin: 0,
                  float: "right",
                  clear: "both",
                  marginTop: 25,
                  width: "90px",
                  marginBottom: 20,
                }}
                disabled={confirmPasswordError || passwordError}
                onClick={handleSubmit}
              >
                Update
              </Button>
            </Form>
          </Form.Group>
        </div>
      </div>
      <Footer />
    </>
  );
}
