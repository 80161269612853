import axios from "axios";
import "../components/Sys/config";

var apiUrl = `${process.env.REACT_APP_BASE_URL}`;

export class NotificationServices {
  constructor() {
    axios.interceptors.request.use(
      function (config) {
        let token = localStorage.getItem("ssoToken");
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      (error) => {
        
        return error;
      }
    );
  }
  getAllNotifications() {
    return axios.get(apiUrl + "api/all_notifications/").then((res) => res.data);
  }
  // getAllCreatePostDislike() {
  //   return axios.get(apiUrl + `api/post/?type=dislike`).then((res) => res.data);
  // }
  // addCommentLikePost(payload) {
  //   return axios.post(apiUrl + `api/comment/`, payload).then((res) => res.data);
  // }
  getUserChatList() {
    return axios.get(apiUrl + "chat/list_rooms/").then((res) => res.data);
  }

  getUserChat(id, loadMoreCount) {
    return axios
      .get(apiUrl + `chat/get_message?room_id=${id}`)
      .then((res) => res.data);
  }
  postCreateChatWithNewUser(payload) {
    return axios
      .post(apiUrl + `chat/create_room/`, payload)
      .then((res) => res.data);
  }
 
  getSearchNewUser(name) {
    return axios
      .get(apiUrl + `usersfilters/?block=true&search=${name}`)
      .then((res) => res.data);
  }
}
