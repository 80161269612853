import React, { useState, useEffect } from "react";
import "semantic-ui-css/semantic.min.css";
import { Button, Form, Header, Image } from "semantic-ui-react";
import "../../App.scss";
import Footer from "../FooterLinks/Footer";
import { toast } from "react-toastify";
import "../FooterLinks/AboutUs/about-us.scss";
import "./Login.scss";
import { authServices } from "../../Services/Auth";
import LogoHeader from "../FooterLinks/AboutUs/LogoHeader";
import axios from "axios";

export default function ForgotPassword(props) {
  const [Email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const submitForgetPassword = () => {
    if (Email) {
      let payload = {
        email: Email,
      };
     
      authServices.forgotPassword(payload).then((data) => {
        toast.success(data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        props.history.push({
          pathname: "/reset-password",
          state: { userEmail: data.data.email },
        });
      },(error)=>{
        console.log("err",error)
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    }
  };
  return (
    <div className="login-page">
      <LogoHeader />
      <div className="main-content" style={{ marginBottom: "20%" }}>
        <Header as="h3">Enter your email</Header>
        <div className="forgot-pwd-main">
          <div className="forgot-pwd-form">
            <Form onSubmit={submitForgetPassword}>
              <Form.Input
                placeholder="Enter Email"
                className="input-email-forgot"
                name="email"
                type="email"
                required
                onChange={handleEmailChange}
                value={Email}
              />
              <Button className="send-contact-us forgot-pwd-send" type="submit">
                Send
              </Button>
            </Form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
