import React, { useState, useEffect } from "react";
import "semantic-ui-css/semantic.min.css";
// import "../../Login/Login.scss";
import {
  Button,
  
  Header,
 
  Image,
} from "semantic-ui-react";
import "../../../App.scss";
import Footer from "../Footer";
import "./about-us.scss";
import LogoHeader from "./LogoHeader";
import { UserProfileServices } from "../../../Services/UserProfileServices";
import { toast } from "react-toastify";

const userProfileServices = new UserProfileServices();

export default function Friends(props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [receivedFriendsList, setReceivedFriendsList] = useState([]);
  const [sentFriendsList, setSentFriendsList] = useState([]);

  useEffect(() => {
    
    getReceivedFriendsRequests();
    getSentFriendsRequest();
  }, []);

  const getReceivedFriendsRequests = () => {
    setIsLoading(true);
    userProfileServices.getReceivedFriendsRequests().then(
      (data) => {
        setReceivedFriendsList(data);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        
      }
    );
  };

  const getSentFriendsRequest = (id) => {
    setIsLoading(true);
    userProfileServices.getSentFriendsRequest().then(
      (data) => {
        setIsLoading(false);
        setSentFriendsList(data);
        // toast.success(data.message ? data.message : data.response, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      },
      (error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
        
      }
    );
  };

  //Approve request
  const handleApproveRequest = (item) => {
    const payload = {
      request_id: item.uuid,
    };
    userProfileServices
      .postApproveRequest(payload)
      .then((data) => {
       console.log("DATA",data)
        getReceivedFriendsRequests();
        toast.success(data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
       
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  //withdraw request
  const handleWithDrawSentRequest=(id)=>{
    const payload={
      request_id: id
    }
    userProfileServices.postWithDrawSentRequest(payload)
    .then((data) => {
     
      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      getSentFriendsRequest();
     
    })
    .catch((error) => {
    
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
  }

  const handleRejectRequest=(id)=>{
    const payload={
      request_id: id
    }
    userProfileServices.postRejectReceivedRequest(payload)
    .then((data) => {
   
      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      getReceivedFriendsRequests();
     
    })
    .catch((error) => {
  
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
  }


  return (
    <div className="login-page">
      <LogoHeader />
      {/* <Segment className="loader-custom">
        <Dimmer active={isLoading} inverted>
          <Loader inverted content="Loading" />
        </Dimmer>
      </Segment> */}

      <div className="main-content">
        <Header as="h3">Follow Requests</Header>
        {receivedFriendsList.length > 0 ? (
          receivedFriendsList.map((item) => (
              <div
                className="div-inline"
                style={{ marginBottom: 15, display: "flex" }}
              >
                <div
                  className="div-inline"
                  style={{ width: "70%", display: "flex" }}
                >
                  <div className="img-block-user">
                    <div className="div-inline img-div-follow">
                      <Image
                        src={
                          item.photo_url
                            ? item.sender.photo_url
                            : "https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"
                        }
                        width="100"
                        height="100"
                        style={{borderRadius:"50%"}}
                      />
                    </div>
                  </div>
                  <p className="div-inline text-block-user">
                    <Header as="h3">
                      {item.sender.first_name} {item.sender.last_name}
                    </Header>
                    <Header as="h5" style={{ color: "#838392", margin: 0 }}>
                      {item.sender.email}
                    </Header>
                  </p>
                </div>
                {!item.is_following && (
                  <div  style={{ width: "25%",display:"flex" }}>
                    <Button
                      className="send-contact-us"
                      style={{ borderRadius: 4, padding: 10 }}
                      onClick={() => handleApproveRequest(item)}
                    >
                      Approve
                    </Button>
                    <Button
                      className="send-contact-us"
                      style={{ borderRadius: 4, padding: 10 }}
                      onClick={() => handleRejectRequest(item.uuid)}
                    >
                      Reject
                    </Button>
                  </div>
                )}
              </div>
            )
          )
        ) : (
          <div>
            <Header as="h3" textAlign="center">
              No record found
            </Header>
          </div>
        )}
      </div>
      <hr></hr>
      <div className="main-content" style={{ marginBottom: "20%" }}>
        <Header as="h3">Pending Follow Requests</Header>
        {sentFriendsList.length > 0 ? (
          sentFriendsList.map((item) => (
              <div
                className="div-inline"
                style={{ marginBottom: 15, display: "flex" }}
              >
                <div
                  className="div-inline"
                  style={{ width: "70%", display: "flex" }}
                >
                  <div className="img-block-user">
                    <div className="div-inline img-div-follow">
                      <Image
                        src={
                          item.receiver.photo_url
                            ? item.photo_url
                            : "https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"
                        }
                        width="100"
                        height="100"
                        style={{borderRadius:"50%"}}
                      />
                    </div>
                  </div>
                  <p className="div-inline text-block-user">
                    <Header as="h3">
                      {item.receiver.first_name} {item.receiver.last_name}
                    </Header>
                    <Header as="h5" style={{ color: "#838392", margin: 0 }}>
                      {item.receiver.email}
                    </Header>
                    <Header as="h5" style={{ color: "#6C0AC7", margin: 0 }}>
                      {item.status}
                    </Header>
                  </p>
                </div>

                <div className="div-inline-status" style={{ width: "30%" }}>
                  <Button
                    className="send-contact-us"
                    style={{ borderRadius: 4, padding: 10 }}
                    onClick={()=>handleWithDrawSentRequest(item.uuid)}
                  >
                   withdraw
                  </Button>
                </div>
              </div>
            )
          )
        ) : (
          <div>
            <Header as="h3" textAlign="center">
              No record found
            </Header>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}
