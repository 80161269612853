import React, { useState, useEffect } from "react";
import "semantic-ui-css/semantic.min.css";
import { Segment, Dimmer, Loader } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { UserProfileServices } from "../../Services/UserProfileServices";
import { toast } from "react-toastify";
import axios from "axios";

const Activate = (props) => {
  //const userProfileServices = new UserProfileServices();
  const [isLoading, setisLoading] = useState(true);

  let token = props.match.params.token;
  var apiUrl = `${process.env.REACT_APP_BASE_URL}`;
  var myurl = apiUrl + `user/${props.match.params.id}`;
  useEffect(() => {
    setisLoading(true);
    console.log("token", token);
    console.log("IDD", props.match.params.id);

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      // body: JSON.stringify(payload),
    };
    if (token && props.match.params.id) {
      fetch(myurl, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          //let resp = JSON.parse(json);
          console.log("",resp)
          if (resp) {
            var payload = {
              email: resp.email,
              phone_number: resp.phone_number,
              country: resp.country,
              website: resp.website,
              city: resp.city,
              me: resp.me,
              like: resp.like,
              dislike: resp.dislike,
              username: resp.username,
              photo_url: resp.photo_url,
              cover_photo_url: resp.cover_photo_url,
              is_activated: true,
            };
            console.log("Payload", payload);
            if (resp.uuid === props.match.params.id) {
              updateProfile(payload, resp.uuid);
            } else {
              setisLoading(false);
              toast.error("Something went wrong", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
        })
        // .catch((err) => {
        //   // toast.warning("Please login first to activate your account", {
        //   //   position: toast.POSITION.TOP_RIGHT,
        //   // });
        //   // props.history.push("/");
        //   toast.error("Something went wro", {
        //     position: toast.POSITION.TOP_RIGHT,
        //   });
        //   setisLoading(false);
        // });
    }
  }, []);

  const updateProfile = (payload, id) => {
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    };
    let url=apiUrl+`user/${id}/`
    fetch(url, requestOptions)
       .then((resp) => resp.json())
      .then((resp) => {
        setisLoading(false);
        props.history.push("/public-reactions");
        toast.success("Profile activated", {
          position: toast.POSITION.TOP_RIGHT,
        });
        props.history.push("/public-reactions");
      })
      .catch((err) => {
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
        props.history.push("/");
        setisLoading(false);
      });
  };

  return (
    <div>
      <Segment className="loader-custom">
        <Dimmer active={isLoading} inverted>
          <Loader inverted content="Loading" />
        </Dimmer>
      </Segment>
    </div>
  );
};

export default withRouter(Activate);
