import axios from "axios";
import "../components/Sys/config";

var apiUrl = `${process.env.REACT_APP_BASE_URL}`;

export class UserProfileServices {
  constructor() {
    axios.interceptors.request.use(
      function (config) {
        let token = localStorage.getItem("ssoToken");
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      (error) => {
  
        return error;
      }
    );
  }
  //user profile --------------//
  getUserProfileDetail(userId) {
       
    var id = localStorage.getItem("uid");
    var myurl = apiUrl + `user/${userId ? userId : id}`
    return axios
      .get(myurl)
      .then((res) => res.data);
  }
  // increase share count 
  postIncreaseShareCount(id){
    let payload={
      post_id:id
    }
    return axios
    .post(apiUrl + `api/increase_share_count/`,payload)
    .then((res) => res.data);
  }

  //specify getting data by user id
  getMyFavLikePost(id) {
    return axios
      .get(apiUrl + `api/favourites/?type=like${id ? `&user_id=${id}` : ""}`)
      .then((res) => res.data);
  }
  getMyFavDisLikePost(id) {
    return axios
      .get(apiUrl + `api/favourites/?type=dislike${id ? `&user_id=${id}` : ""}`)
      .then((res) => res.data);
  }
  handleClickFollowBack(id) {
    var payload = {
      receiver: id,
      sender: localStorage.getItem('uid'),
    };
    return axios.post(apiUrl + `send_follow_request/`, payload).then((res) => res.data);
  }
  getMyLikePost(url) {
    return axios.get(apiUrl + url).then((res) => res.data);
  }
  getMyDislike(url) {
    return axios.get(apiUrl + url).then((res) => res.data);
  }
  getUserFollowersOrFollowing(id) {
    
    return axios
      .get(apiUrl + `api/follow/${id ? `?user_id=${id}` : ""}`)
      .then((res) => res.data);
  }
  handleClickUnFollow(id, type) {
    return axios
      .delete(apiUrl + `api/remove_follow/${id}?type=${type}`)
      .then((res) => res.data);
  }
  userProfileUpdate(payload) {
    var id = localStorage.getItem("uid");
    return axios.patch(apiUrl + `user/${id}/`, payload).then((res) => res.data);
  }
  getPrivacySettingProfile(id) {
    return axios.get(apiUrl + `privacy_setting/`).then((res) => res.data);
  }
  userPrivacySetting(payload) {
    return axios
      .post(apiUrl + `privacy_setting/`, payload)
      .then((res) => res.data);
  }
  getNotificationSetting() {
    return axios.get(apiUrl + "post_notifications/").then((res) => res.data);
  }
  updateNotificationSetting(payload) {
    return axios
      .post(apiUrl + `post_notifications/`, payload)
      .then((res) => res.data);
  }
  getPostDetailUpdate(id) {
    return axios.get(apiUrl + `api/post_by_id/${id}`).then((res) => res.data);
  }
  // updateCreateUserQuickBloxOccupantId(payload) {
  //   var id = localStorage.getItem("uid");
  //   return axios.patch(apiUrl + `user/${id}/`, payload).then((res) => res.data);
  // }
  getFriendListUsers(search) {
    return axios
      .get(apiUrl + `api/friends/?search=${search}`)
      .then((res) => res.data);
  }
  getFilterAllUserListHeader(search) {
    return axios
      .get(apiUrl + `usersfilters/?block=true&search=${search}`)
      .then((res) => res.data);
  }
  getBlockedUsersList() {
    return axios.get(apiUrl + "block_user/").then((res) => res.data);
  }
  postUnblockUser(payload) {
    return axios
      .post(apiUrl + "unblock_user/", payload)
      .then((res) => res.data);
  }
  postInviteUser(payload) {
    return axios.post(apiUrl + "invite_user/", payload).then((res) => res.data);
  }
  getReceivedFriendsRequests() {
    return axios
      .get(apiUrl + "list_received_follow_request/")
      .then((res) => res.data);
  }
  getSentFriendsRequest() {
    return axios
      .get(apiUrl + "list_sent_follow_request/")
      .then((res) => res.data);
  }
  postApproveRequest(payload) {
    return axios
      .post(apiUrl + "approve_follow_request/", payload)
      .then((res) => res.data);
  }
  postWithDrawSentRequest(payload){
    return axios
      .post(apiUrl + "cancel_follow_request/", payload)
      .then((res) => res.data);
  }
  postRejectReceivedRequest(payload){
    return axios
      .post(apiUrl + "reject_follow_request/", payload)
      .then((res) => res.data);
  }
  getAllUser(){
    return axios
      .get(apiUrl + `usersfilters/?block=true`)
      .then((res) => res.data);
  }
  getAllUnblockUsers(name){
    return axios
    .get(apiUrl + `unblock_users/?block=true&search=${name}`)
    .then((res) => res.data);
  }
  postBlockUser(payload){
    return axios
    .post(apiUrl + `block_user/`,payload)
    .then((res) => res.data);
  }
  changeMyPassword(payload){
    return axios
    .put(apiUrl + `change_password/`,payload)
    .then((res) => res.data)
  }
}
