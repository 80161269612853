import axios from "axios";
import "../components/Sys/config";

var apiUrl = `${process.env.REACT_APP_BASE_URL}`;

export class HomeServices {
  constructor() {
    axios.interceptors.request.use(
      function (config) {
        let token = localStorage.getItem("ssoToken");
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      (error) => {
      
        return error;
      }
    );
  } 
  getAllCreatePost() {
    return axios.get(apiUrl + "api/post/?type=like").then((res) => res.data);
  }
  getAllCreatePostDislike() {
    return axios.get(apiUrl + `api/post/?type=dislike`).then((res) => res.data);
  }
  addCommentLikePost(payload) {
    return axios.post(apiUrl + `api/comment/`, payload).then((res) => res.data);
  }
  getCommentParticularPost(id) {
    return axios
      .get(apiUrl + `api/get_post_comments/${id}`)
      .then((res) => res.data);
  }
  getLikeOrDislikeParticularPost(payload) {
    return axios
      .post(apiUrl + `api/reactions/`, payload)
      .then((res) => res.data);
  }
  addFavouratePost(url, payload) {
    return axios.post(apiUrl + url, payload).then((res) => res.data);
  }
  deleteMyPost(url, payload) {
    return axios.delete(apiUrl + url).then((res) => res.data);
  }
  reportPost(url, payload) {
    return axios.post(apiUrl + url, payload).then((res) => res.data);
  }
  createMyPostLike(payload, id) {
    if (id) {
      return axios
        .patch(apiUrl + `api/post_update/${id}`, payload)
        .then((res) => res.data);
    } else {
      return axios.post(apiUrl + "api/post/", payload).then((res) => res.data);
    }
  }
  // handleClickPostSee(id) {
  //   var payload = {
  //     post: id,
  //   };
  //   return axios.post(apiUrl + `api/seen/`, payload).then((res) => res.data);
  // }
  handleClickFollowingUser(id) {
    
  }
  postFriendRequest(payload) {
    return axios
      .post(apiUrl + `send_follow_request/`, payload)
      .then((res) => res.data);
  }
  getPostById(id){
    return axios
    .get(apiUrl + `api/post_by_id/${id}`)
    .then((res) => res.data);
  }
}
