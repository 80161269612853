import React, { useState,} from "react";
import "semantic-ui-css/semantic.min.css";
//import "../../Login/Login.scss";
import {
  Button,
 
  Header,
  
} from "semantic-ui-react";

import "../../../App.scss";
import Footer from "../Footer";
import "./about-us.scss";
import LogoHeader from "./LogoHeader";
import { UserProfileServices } from "../../../Services/UserProfileServices";
import { toast } from "react-toastify";

export default function InviteFriend(props) {
  const [InviteEmail, setInviteEmail] = useState("");

  const userProfileServices = new UserProfileServices();
  //submit user invite
  const submitUserInvite = (event) => {
    event.preventDefault();
   
    let payload = {
      email: InviteEmail,
    };
    userProfileServices.postInviteUser(payload).then(
      (data) => {
        toast.success(data.message ? data.message : data, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setInviteEmail("");
      },
      (error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };
  const handleEmailChange = (e) => {
    setInviteEmail(e.target.value);
  };
  return (
    <>
    <div className="login-page "  style={{paddingBottom:"20%",height:"auto" }}>
      <LogoHeader />
      <div className="main-content" style={{ marginBottom: "20%" }}>
        <Header as="h3" textAlign="center">
          Invite a friend
        </Header>
        <p>
          Hey, check out Moodflik, a unique micro-blogging platform that allows
          you to see and share reactions to things and events you love and
          dislike in real-time, simultaneously.
        </p>
        <p>
            join me and others today at www.moodflik.com
        </p>
        <p>
          <a href="https://www.moodflik.com">
            <b>www.moodflik.com</b>
          </a>
        </p>
        <form onSubmit={submitUserInvite}>
          <div className="ui big icon input">
            <input
              type="email"
              placeholder="Enter email address"
              required
              onChange={handleEmailChange}
            />
            <i className="email icon"></i>
          </div>
          <Button
            className="send-contact-us"
            style={{
              width: 150,
              borderRadius: 4,
              marginLeft: 0,
              marginTop: 30,
            }}
            type="submit"
          >
            Invite
          </Button>
        </form>
      </div>
   
    </div>
    
       <Footer />
       
       </>
  );
}
