import React, { useState, useEffect, useRef } from "react";
import "semantic-ui-css/semantic.min.css";
import moment from "moment";

import {
  Button,
  Grid,
  Form,
  Header,
  Modal,
  Image,
  Segment,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import { Icon } from "semantic-ui-react";
import "../../App.scss";
import defaultUserImage from "../../assets/img/user-image.png";
import "./chating.scss";
import "../FooterLinks/AboutUs/about-us.scss";
import "../Home/home.scss";
import { withRouter, useLocation } from "react-router-dom";
import HeaderHome from "../Home/HeaderHome";
import { NotificationServices } from "../../Services/NotificationServices";
import Picker from "emoji-picker-react";
import { toast } from "react-toastify";
//api URIs
var socUrl = `${process.env.REACT_APP_SOCKET_URL}`;
//services
const notificationServices = new NotificationServices();

function UserListChat(props) {
  const location = useLocation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [friendListNew, setFriendListNew] = useState([]);
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  //const [activeDialog, setActiveDialog] = useState({});
  const [userListChat, setUserListChat] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  // const [messageListParticular, setMessageListParticular] = useState([]);
  const [userDetailDialog, setUserDetailDialog] = useState({});
  const [isOpenEmoji, setIsOpenEmoji] = useState(false);

  // const [activeUserChat, setActiveUserChat] = useState("");
  const [client, setClient] = useState();

  //messages
  const [Messages, setMessages] = useState([]);
  //add new user
  //const [newUser, setNewUser] = useState([]);

  const messagesEndRef = useRef(null);

  useEffect(() => {
    getUserList();
  }, []);

  const handleSearchValue = (e) => {
    setSearchVal(e.target.value);
    handleSearchUser(e.target.value);
  };

  //search friends to add to list
  const handleSearchUser = (searchVal) => {
    if (searchVal) {
      notificationServices.getSearchNewUser(searchVal).then((data) => {
        if (data?.length === 0) {
          toast.warning("No user Found with this name", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setFriendListNew(data);
        }
      });
    }
  };
  const handleOpenModal = () => {
    setOpenAddUserModal(true);
  };

  const handleCloseModal = () => {
    //setNewUser([]);
    setOpenAddUserModal(false);
  };

  //add new user for chat

  const addNewUser = (item) => {
    const currentUser = localStorage.getItem("uid");
    // console.log("currentuser", currentUser);
    // setNewUser((prev) => [...prev, currentUser]);
    let user = [];
    user.push(currentUser, item.uuid);

    notificationServices
      .postCreateChatWithNewUser({
        name: item.username,
        type: "private",
        participants: {
          user: user,
        },
      })
      .then((res) => {
        toast.success("Chat Created", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setOpenAddUserModal(false);
        getUserList();
      });
  };
  // get all chat user list
  const getUserList = () => {
    setIsLoading(true);
    notificationServices
      .getUserChatList()
      .then((data) => {
        setUserListChat(data);

        setIsLoading(false);
        if (location.state.chat_room) {
          // console.log("USERID",location.state.)
          let user = {
            room_id: location.state.chat_room.chat_room,
            uuid: location.state.chat_room.uuid,
            chat_name: location.state.chat_room.user,
            image: location.state.sender_image,
          };
          handleUserChat(user);
        }
      })
      .catch((err) => {});
  };

  //Socket open
  const openEventListener = (event) => {};
  //messages
  const messageLisntner = (data) => {
    let message = JSON.parse(data.data);

    if (message.type === "chat") {
      // message.timestamp=new Date('2022-06-29T16:52:48.000Z').toLocaleString()
      console.log("Message", message);
      setMessages((prev) => [...prev, message]);
    }
  };
  //socket connection
  const connectSocket = (user) => {
    let clnt = new WebSocket(
      `${socUrl}ws/chat/${user.room_id}/?token=${localStorage.getItem(
        "ssoToken"
      )}`
    );

    clnt.addEventListener("open", openEventListener);
    setClient(clnt);
    clnt.addEventListener("message", messageLisntner);
    setUserDetailDialog(user);
  };
  // get user chat messages \\ start chat
  const handleUserChat = (user) => {
    if (client) {
      client.onclose = () => {};
      client.close();
    }
    setIsLoading(true);
    notificationServices
      .getUserChat(user.uuid, 4)
      .then((data) => {
        console.log("messages", data);
        setMessages(data);
        connectSocket(user);
        setIsLoading(false);
      })
      .catch((err) => {});
  };
  // send messages
  const handleInputMessages = (e) => {
    setMessageInput(e.target.value);
  };

  const sendMessage = (e) => {
    e.preventDefault();
    if (messageInput.length > 0) {
      if (client) {
        client.send(
          JSON.stringify({
            user: localStorage.getItem("uid"),
            message: messageInput,
          })
        );
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }

      setMessageInput("");
    }
  };
  const handleKeypress = (e) => {
    //console.log("message", e);
    // console.warn("input message", messageInput);
    // setMessages((prev) => [...prev, { msg: messageInput }]);
  };

  var URL_REGEX =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;

  return (
    <div className="login-page">
      <HeaderHome />
      <Segment className="loader-custom">
        <Dimmer active={isLoading} inverted>
          <Loader inverted content="Loading" />
        </Dimmer>
      </Segment>

      <div className="main-content" style={{ height: "100vh" }}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={10}>
              <Header as="h3">Messages</Header>
            </Grid.Column>
            <Grid.Column width={6}>
              <Button
                className="send-contact-us"
                style={{
                  float: "right",
                  borderRadius: "10px",
                }}
                onClick={handleOpenModal}
              >
                Add User
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid>
          <Grid.Column mobile={16} tablet={6} computer={6}>
            <div className="user-chat-list-box">
              {userListChat.length > 0 &&
                userListChat.map((user, idx) => {
                  return (
                    <div key={idx} className="user-chat-box-content">
                      <div
                        className="user-chat"
                        onClick={() => handleUserChat(user)}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="user-name-image">
                          <Image
                            src={user.image ? user.image : defaultUserImage}
                            width="50"
                            height="50"
                            className="image-user"
                          />
                          <Header as="h5" style={{ margin: 0 }}>
                            {user.chat_name}
                            <Header.Subheader>
                              {user.latest_message}
                            </Header.Subheader>
                          </Header>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "end",
                            flexDirection: "column",
                            position: "relative",
                          }}
                        >
                          {user.unseen_message_count > 0 ? (
                            <span className="notification-chat">
                              {user.unseen_message_count}
                            </span>
                          ) : null}
                          {moment
                            .utc(user.datetime)
                            .local()
                            .format("DD/MM/YYYY hh:mm a")}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <div className="chat-person-name">
              {userDetailDialog && (
                <Header as="h4">
                  <Image
                    src={
                      userDetailDialog
                        ? userDetailDialog.image
                        : defaultUserImage
                    }
                    width="100"
                    height="100"
                    className="image-user"
                    style={{
                      borderRadius: "50%",
                      width: "40px",
                      height: "40px",
                    }}
                  />
                  {userDetailDialog.chat_name}
                </Header>
              )}
            </div>
            <div className="user-chat-detail-box">
              <div className="user-chat-detail-content">
                {Messages.length > 0 &&
                  Messages.map((item, idx) => {
                    return (
                      <div key={idx}>
                        {localStorage.getItem("uid") === item.user ? (
                          <div className="user-chat-detail">
                            <div className="sender-message">
                              <p style={{ color: "white" }}>
                                {item.message.split(" ").map((word) => {
                                  return word.match(URL_REGEX) ? (
                                    <>
                                      <a href={word} target="_blank">
                                        {word}
                                      </a>{" "}
                                    </>
                                  ) : (
                                    word + " "
                                  );
                                })}
                              </p>
                              <div className="chat-time">
                                {moment
                                  .utc(item.datetime)
                                  .local()
                                  .format("DD/MM/YYYY hh:mm a")}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="reciver-message">
                            <p> {item.message.split(" ").map((word) => {
                                  return word.match(URL_REGEX) ? (
                                    <>
                                      <a href={word} target="_blank">
                                        {word}
                                      </a>{" "}
                                    </>
                                  ) : (
                                    word + " "
                                  );
                                })}</p>
                            <div className="receiver-message-time">
                              {moment
                                .utc(item.datetime)
                                .local()
                                .format("DD/MM/YYYY hh:mm a")}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                <div ref={messagesEndRef} />
              </div>

              <div className="bottom-input-send-message">
                <div className="input-box-send">
                  <Icon
                    name="smile outline"
                    className="smile-in-chat"
                    onClick={() => setIsOpenEmoji(!isOpenEmoji)}
                  />

                  {isOpenEmoji && (
                    <div className="emoji-style-custom">
                      <Picker
                        onEmojiClick={(event, emojiObject) =>
                          setMessageInput(messageInput + emojiObject.emoji)
                        }
                      />
                    </div>
                  )}

                  <form
                    onSubmit={sendMessage}
                    style={{ display: "flex", width: "100%" }}
                  >
                    <Form.Input
                      fluid
                      type="text"
                      value={messageInput}
                      className="input-box-custom"
                      onChange={handleInputMessages}
                      placeholder="Type Here..."
                      name="messageInput"
                      autoFocus={true}
                      onKeyPress={(e) => handleKeypress(e)}
                      icon={
                        <Icon
                          name="send"
                          link
                          style={{ color: "#bec5d3" }}
                          onClick={sendMessage}
                        />
                      }
                    />
                  </form>
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid>

        <Modal
          onClose={handleCloseModal}
          onOpen={handleOpenModal}
          open={openAddUserModal}
          size="tiny"
        >
          <Modal.Content>
            <Header textAlign="center">Add New User</Header>
            <div className="input-search-friend">
              <Form.Input
                fluid
                type="text"
                placeholder="Search Name"
                name="name"
                onChange={handleSearchValue}
                value={searchVal}
              />
            </div>

            <div className="add-user-friend-list-modal">
              <Grid>
                {friendListNew.length > 0 &&
                  friendListNew.map((item, idx) => {
                    return (
                      <>
                        <Grid.Column
                          mobile={12}
                          tablet={12}
                          computer={12}
                          key={idx}
                        >
                          <p>
                            {item.first_name} {""}
                            {item.last_name}
                          </p>
                          <p>
                            <span>{item.email}</span>
                          </p>
                        </Grid.Column>
                        <Grid.Column mobile={4} tablet={4} computer={4}>
                          <Button
                            onClick={() => addNewUser(item)}
                            className="save-btn"
                            style={
                              {
                                // margin: 0,
                              }
                            }

                            //   disabled={disableSubmit}
                          >
                            Add
                          </Button>
                        </Grid.Column>
                      </>
                    );
                  })}
              </Grid>
            </div>
          </Modal.Content>
          <Modal.Actions
            style={{
              backgroundColor: "#fff",
              textAlign: "center",
              borderTop: "none",
            }}
          >
            {/* <Button
              className="cancle-btn"
              style={{
                width: " 35%",
                margin: 0,
              }}
              onClick={handleSearchUser}
            >
              Search user
            </Button> */}
            <Button
              className="cancle-btn"
              style={{
                width: " 35%",
                margin: 0,
                marginLeft: "20px",
              }}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
export default withRouter(UserListChat);
