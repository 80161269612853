import React, { useState, useEffect } from "react";
import "semantic-ui-css/semantic.min.css";
// import "../../Login/Login.scss";
import { Button, Header, Image, Input, Popup } from "semantic-ui-react";
import { Icon } from "semantic-ui-react";
import "../../../App.scss";
import Footer from "../Footer";
import "./about-us.scss";
import LogoHeader from "./LogoHeader";
import { UserProfileServices } from "../../../Services/UserProfileServices";
import { toast } from "react-toastify";
import defaultUserImage from "../../../assets/img/user-image.png";

export default function BlockedUserList(props) {
  const [searchVal, setSearchVal] = useState("");
  const [BlockedUserList, setBlockedUserList] = useState([]);
  const [searchUserAllList, setsearchUserAllList] = useState([]);
  const userProfileServices = new UserProfileServices();
  useEffect(() => {
    getBlockedUser();
  }, []);

  // //get all user list
  const getAllUsers = (name) => {
    userProfileServices.getAllUnblockUsers(name).then(
      (data) => {
        setsearchUserAllList(data);
      },
      (error) => {}
    );
  };
  //block user
  const handleBlockUser = (id) => {
    const payload = {
      user_id: id,
    };
    userProfileServices.postBlockUser(payload).then(
      (data) => {
        toast.success(data, {
          position: toast.POSITION.TOP_RIGHT,
        });
        getAllUsers();
        getBlockedUser();
      },
      (error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };
  //get blocked user list
  const getBlockedUser = () => {
    userProfileServices.getBlockedUsersList().then(
      (data) => {
        setBlockedUserList(data);
      },
      (error) => {}
    );
  };
  //unblock user
  const handleUnblockUser = (id) => {
    let payload = {
      user_id: id,
    };
    userProfileServices.postUnblockUser(payload).then(
      (data) => {
        toast.success(data.message ? data.message : data, {
          position: toast.POSITION.TOP_RIGHT,
        });
        getBlockedUser();
      },
      (error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };
  const handleSearchInput = (e) => {
    setSearchVal(e.target.value);
    getAllUsers(e.target.value);
  };
  const handleProfileOpen = (id) => {
    props.history.push(`/user-profile/${id}`);
  };

  return (
    <>
      <div
        className="login-page"
        style={{ paddingBottom: "30px", height: "auto" }}
      >
        <LogoHeader />
        <div className="main-content" style={{ marginBottom: "20%" }}>
          <Header as="h3" textAlign="center">
            Blocked Users
          </Header>
          <Header as="h4">
            <Icon name="dont" />
            Block A User
          </Header>
          <div className="div-inline search-header-home" style={{width:"57%"}}>
            <Popup
              trigger={
                <Input
                  fluid
                  type="text"
                  onChange={handleSearchInput}
                  placeholder="Search User"
                  name="searchVal"
                  value={searchVal}
                />
              }
              on="click"
              position="bottom center"
            >
              <div className="popup-search-user-content">
                {searchVal && searchUserAllList.length > 0 ? (
                  searchUserAllList.map((item) => {
                    return (
                      <>
                        <div className="pop-up-search-user-header">
                          <div
                            className="search-header-all-user"
                            style={{ cursor: "pointer" }}
                          >
                            <div
                              onClick={() => handleProfileOpen(item.uuid)}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Image
                                src={
                                  item.photo_url
                                    ? item.photo_url
                                    : defaultUserImage
                                }
                                width="50"
                                height="50"
                                className="image-user-search"
                              />
                              <Header as="h5" style={{ margin: 0 }}>
                                {item.first_name}
                                {item.last_name}
                                <Header.Subheader>
                                  {item.username}
                                </Header.Subheader>
                              </Header>
                            </div>
                            <div
                              className="div-inline"
                              style={{ width: "30%" }}
                            >
                              <Button
                                className="send-contact-us"
                                style={{ borderRadius: 4, padding: 10 }}
                                onClick={() => handleBlockUser(item.uuid)}
                              >
                                Block
                              </Button>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                ) : null}
              </div>
            </Popup>
          </div>
          <br></br>
          <p style={{  fontSize: 20, fontWeight: "bold" }}>
            Blocked Users:
          </p>
          {BlockedUserList && BlockedUserList.length > 0 ? (
            BlockedUserList.map((user, idx) => (
              <div
                className="div-inline"
                style={{ marginBottom: 15, display: "flex" }}
              >
                <div
                  className="div-inline"
                  style={{ width: "70%", display: "flex" }}
                >
                  <div className="img-block-user">
                    <div className="div-inline img-div">
                      <Image
                        //src="https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"
                        src={user.photo_url}
                        alt="image not found"
                        width="100"
                        height="100"
                        style={{ borderRadius: "50%" }}
                      />
                    </div>
                  </div>
                  <p className="div-inline text-block-user">
                    {user.first_name} {user.last_name}
                  </p>
                </div>
                <div className="div-inline" style={{ width: "30%" }}>
                  <Button
                    className="send-contact-us"
                    style={{ borderRadius: 4, padding: 10 }}
                    onClick={() => handleUnblockUser(user.uuid)}
                  >
                    Unblock
                  </Button>
                </div>
              </div>
            ))
          ) : (
            <div>
              <Header as="h3" textAlign="center">
                No record found
              </Header>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
