import React, { useState, useEffect } from "react";
import "semantic-ui-css/semantic.min.css";
// import "../../Login/Login.scss";
import { Grid, Form, Header, Image, Dropdown } from "semantic-ui-react";
import { Icon } from "semantic-ui-react";
import "../../App.scss";
import moment from "moment";
//import Footer from "../FooterLinks/Footer";
import "../FooterLinks/AboutUs/about-us.scss";
import "./home.scss";
import { withRouter } from "react-router-dom";
import userImage from "../../assets/img/user-image.png";
import PublicLike from "../FooterLinks/PublicLike";
import PublickDislike from "../FooterLinks/PublickDislike";
import { HomeServices } from "../../Services/HomeServices";
import { toast } from "react-toastify";
import { UserProfileServices } from "../../Services/UserProfileServices";
import LogoHeader from "../FooterLinks/AboutUs/LogoHeader";
import Footer from "../FooterLinks/Footer";

const homeServices = new HomeServices();
const userProfileServices = new UserProfileServices();

function PostDetails(props) {
  const [commentIndex, setCommentIndex] = useState("");
  const [commentList, setCommentList] = useState([]);
  const [commentInput, setCommentInput] = useState([]);
  const [isDisableInput, setIsDisableInput] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isShowCommentBox, setIsShowCommentBox] = React.useState(false);
  const [commentDislikeInput, setCommentDislikeInput] = useState([]);
  const [commentDislikeIndex, setCommentDislikeIndex] = useState("");
  const [postUserListLike, setPostUserListLike] = useState("");

  useEffect(() => {
    let id = props.match.params.id;
    setIsLoading(true);
    if (id) {
      if (props.location.state.comment) {
        getPostById(id);
        handleOpenClickComment(id);
      } else {
        getPostById(id);
      }
    }
  }, []);

  // get one post
  const getPostById = (id) => {
    setIsLoading(true);
    homeServices.getPostById(id).then(
      (data) => {
        setPostUserListLike(data);

        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
      }
    );
  };
  //profile view
  const handleProfileClick = (id) => {
    if (id) {
      props.history.push(`/user-profile/${id}`);
    }
  };

  // get comment listing api for particular post
  const handleOpenClickComment = (id, index) => {
    setCommentIndex(index);
    setCommentDislikeIndex("");
    setIsShowCommentBox(!isShowCommentBox);
    homeServices.getCommentParticularPost(id).then(
      (data) => {
        setCommentList(data);
        if (props.isMyProfile) {
          // getMyLikePost();
        } else {
          //getAllCreatePost();
        }
      },
      (error) => {}
    );
  };

  // Like and Dislike of any particular post
  const handleOpenClickLikeDisLikePost = (id, type) => {
    var payload = {
      post: id,
      reaction: type,
    };
    homeServices.getLikeOrDislikeParticularPost(payload).then(
      (data) => {
        getPostById(props.match.params.id);
      },
      (error) => {}
    );
  };
  //share post
  const handleSharePost = (id) => {
    navigator.clipboard.writeText(`http://localhost:3000/post/${id}`);
    userProfileServices.postIncreaseShareCount(id).then((data) => {
     
      toast.success("Link Copied", {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
  };
  // add favourate api
  const handleOpenClickFavouratePost = (id, isFavourate, isLike) => {
    var url = "api/favourites/";
    var payload = {
      post: id,
    };

    homeServices.addFavouratePost(url, payload).then(
      (data) => {
        getPostById(props.match.params.id);
      },
      (error) => {}
    );
  };

  // onChange add comment like
  const handleInputComment = (e, i) => {
    let values = [...commentInput];
    values[i] = e.target.value;
    setCommentInput(e.target.value);
  };

  // onChange add comment dislike
  const handleInputCommentDisLike = (e, i) => {
    let values = [...commentInput];
    values[i] = e.target.value;
    setCommentDislikeInput(values);
  };

  const handleKeypress = (e, id, index, type) => {
    //it triggers by pressing the enter key
    if (e.key === "Enter") {
      handleAddComment(id, index, type);
    }
  };

  const handleAddComment = (id) => {
    if (commentInput) {
      setIsDisableInput(true);

      var params = {
        post: id,
        comment: commentInput,
      };
      homeServices.addCommentLikePost(params).then(
        (data) => {
          getPostById(props.match.params.id);
          setCommentInput([]);
          setCommentDislikeInput([]);
          setIsDisableInput(false);
          toast.success("Commented successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
        (error) => {
          toast.error(error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsDisableInput(false);
        }
      );
    }
  };

  const check5Minute = (date) => {
    var result = timeDifference(new Date(), new Date(date));
    if (result.includes("seconds ago")) {
      return false;
    } else if (result.includes("minutes ago")) {
      let firstWord = result.split(" ")[0];
      var check = parseInt(firstWord.trim());
      if (check > 4) {
        return true;
      } else {
        return false;
      }
    } else if (!result.includes("seconds ago")) {
      return true;
    } else {
      return false;
    }
  };

  const timeDifference = (current, previous) => {
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;
    var elapsed = current - previous;
    if (elapsed < msPerMinute) {
      return Math.round(elapsed / 1000) + " seconds ago";
    } else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + " minutes ago";
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + " hours ago";
    } else if (elapsed < msPerMonth) {
      if (Math.round(elapsed / msPerDay) > 1) {
        return Math.round(elapsed / msPerDay) + " days ago";
      } else {
        return Math.round(elapsed / msPerDay) + " day ago";
      }
    } else if (elapsed < msPerYear) {
      if (Math.round(elapsed / msPerMonth) > 1) {
        return Math.round(elapsed / msPerMonth) + " months ago";
      } else {
        return Math.round(elapsed / msPerMonth) + " month ago";
      }
    } else {
      if (Math.round(elapsed / msPerYear) > 1) {
        return Math.round(elapsed / msPerYear) + " years ago";
      } else {
        return Math.round(elapsed / msPerYear) + " year ago";
      }
    }
  };

  const dateFormat = (date) => {
    var result = timeDifference(new Date(), new Date(date));
    return result;
  };

  //delete post
  const handleClickDeleteMyPost = (id, isLike) => {
    var url = `api/post_update/${id}`;
    homeServices.deleteMyPost(url).then(
      (data) => {
        props.history.push("/public-reactions")
        toast.success(data.message ? data.message : data.response, {
          position: toast.POSITION.TOP_RIGHT,
        });
      },
      (error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };
  //report post
  const handleReportClick = (id) => {
    var url = `api/post_report/`;
    let payload = {
      post: id,
      status: "under_investigation",
    };
    // homeServices.reportPost()

    homeServices.reportPost(url, payload).then(
      (data) => {
        toast.success(data.message ? data.message : data, {
          position: toast.POSITION.TOP_RIGHT,
        });
      },
      (error) => {
        toast.error(error.detail, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };

  return (
    <>
      <div>
        <LogoHeader />
        <div style={{ margin: "10%", marginBottom: "20%" }}>
          <div style={{ marginTop: 30, height: 300 }} className="post-width">
            <Grid>
              <Grid.Column
                style={{
                  width: "100%",
                  marginBottom: "100px",
                  overflow: "scroll",
                }}
              >
                {postUserListLike.post_type == "like" ? (
                  <div
                    className="home-think-like"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      background: "#108A07",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <div className="child-thigs-like">
                      <div>
                        <p style={{ color: "white" }}>
                          Likes (
                          {postUserListLike && postUserListLike.like_count}){" "}
                        </p>
                      </div>

                      <Image
                        src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1601833595390x320408036269073540%2Fsmile.png?w=48&h=48&auto=compress&fit=crop&dpr=1"
                        width="30"
                        height="30"
                        style={{ marginLeft: 12, borderRadius: "50%" }}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className="home-think-like"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      background: "#BF1414",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <div className="child-thigs-like">
                      <div>
                        <p style={{ color: "white" }}>
                          {" "}
                          Dislikes (
                          {postUserListLike && postUserListLike.dislike_count})
                        </p>
                      </div>

                      <Image
                        src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1601829567583x260148826231129120%2Fagr.png?w=48&h=48&auto=compress&fit=crop&dpr=1"
                        width="30"
                        height="30"
                        style={{ marginLeft: 12, borderRadius: "50%" }}
                      />
                    </div>
                  </div>
                )}

                <div
                  className="public-reaction-content"
                  style={{
                    overflow: "unset",
                    height: "auto",
                    marginBottom: "100px",
                  }}
                >
                  {postUserListLike ? (
                    <div>
                      <>
                        <Grid>
                          <Grid.Row>
                            <Grid.Column width={12}>
                              <div
                                className="image-name-user"
                                style={{
                                  cursor: "pointer",
                                  width: "fit-content",
                                }}
                                onClick={() =>
                                  handleProfileClick(postUserListLike.posted_by)
                                }
                              >
                                <Image
                                  src={
                                    postUserListLike.photo_url
                                      ? postUserListLike.photo_url
                                      : userImage
                                  }
                                  width="50"
                                  style={{
                                    borderRadius: "50%",
                                    width: "100px",
                                    height: "100px",
                                  }}
                                />
                                <Header as="h5">
                                  {postUserListLike.first_name ||
                                  postUserListLike.last_name
                                    ? `${postUserListLike.first_name} ${postUserListLike.last_name}`
                                    : `N/A`}
                                </Header>
                              </div>
                            </Grid.Column>
                            <Grid.Column width={12}>
                              <div className="image-name-user">
                                {postUserListLike.post_type === "like" ? (
                                  <Header as="h5" className="font-italic">
                                    {check5Minute(postUserListLike.created_at)
                                      ? "was"
                                      : "is currently"}{" "}
                                    loving:
                                  </Header>
                                ) : (
                                  <Header as="h5" className="font-italic">
                                    {check5Minute(postUserListLike.created_at)
                                      ? ""
                                      : "currently"}{" "}
                                    dislikes:
                                  </Header>
                                )}
                              </div>
                            </Grid.Column>
                            <Grid.Column width={2}>
                              <div className="image-name-user">
                                {/* <Icon
                                    name="add user"
                                    style={{ cursor: "pointer" }}
                                    className="follow-icon"
                                    onClick={() =>
                                      handleClickFollowingUser(postUserListLike.posted_by)
                                    }
                                  /> */}
                              </div>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                        {postUserListLike.post_type === "like" ? (
                          <PublicLike
                            content={postUserListLike.content}
                            why_content={postUserListLike.why_content}
                            media_url={postUserListLike.media_url}
                            content_type={postUserListLike.content_type}
                          />
                        ) : (
                          <PublickDislike
                            content={postUserListLike.content}
                            why_content={postUserListLike.why_content}
                            media_url={postUserListLike.media_url}
                            content_type={postUserListLike.content_type}
                          />
                        )}
                        <p className="text-right">
                          {dateFormat(postUserListLike.created_at)}
                        </p>
                        <div className="text-right public-reaction-icon-content">
                          <Icon
                             title="Add to Favourite" 
                            name={
                              postUserListLike.is_favourite
                                ? "heart"
                                : "heart outline"
                            }
                            className="public-reaction-icon"
                            onClick={() =>
                              handleOpenClickFavouratePost(
                                postUserListLike.uuid,
                                postUserListLike.is_favourite
                              )
                            }
                          />
                          <span className="count-user-reaction">
                            ({postUserListLike.favourite_count})
                          </span>
                          <Icon
                             title="Like" 
                            name={
                              postUserListLike.is_like
                                ? "thumbs up"
                                : "thumbs up outline"
                            }
                            className="public-reaction-icon"
                            onClick={() =>
                              handleOpenClickLikeDisLikePost(
                                postUserListLike.uuid,

                                "like"
                              )
                            }
                          />
                          
                          <span className="count-user-reaction">
                            ({postUserListLike.like_count})
                          </span>
                          <Icon
                             title="Dislike" 
                            name={
                              postUserListLike.is_dislike
                                ? "thumbs down"
                                : "thumbs down outline" 
                            }
                            className="public-reaction-icon"
                            onClick={() =>
                              handleOpenClickLikeDisLikePost(
                                postUserListLike.uuid,

                                "dislike"
                              )
                            }
                          />
                          <span className="count-user-reaction">
                            ({postUserListLike.dislike_count})
                          </span>
                          <Icon
                             title="Add Comment" 
                            name={
                              postUserListLike.is_comment
                                ? "comment"
                                : "comment outline"
                            }
                            className="public-reaction-icon"
                            onClick={() =>
                              handleOpenClickComment(postUserListLike.uuid)
                            }
                          />
                          <span className="count-user-reaction">
                            (
                            {postUserListLike.comments_count
                              ? postUserListLike.comments_count
                              : 0}
                            )
                          </span>
                          <Icon
                             title="Share" 
                            name="share square"
                            className="public-reaction-icon"
                            onClick={()=>handleSharePost(postUserListLike.uuid)}
                          />
                          <span className="count-user-reaction">({postUserListLike.share_count?postUserListLike.share_count:0})</span>
                          <Icon    title="Views"  name={"eye"} className="public-reaction-icon" />

                          <span className="count-user-reaction">
                            ({postUserListLike.seen_count})
                          </span>
                          <Dropdown
                            icon="ellipsis vertical"
                            pointing="right"
                            className="link item"
                          >
                            <Dropdown.Menu>
                              {postUserListLike.posted_by ===
                              localStorage.getItem("uid") ? (
                                <>
                                  <Dropdown.Item
                                    onClick={() =>
                                      props.history.push(
                                        `/create-post/${postUserListLike.uuid}`
                                      )
                                    }
                                  >
                                    Edit
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleClickDeleteMyPost(
                                        postUserListLike.uuid
                                      )
                                    }
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </>
                              ) : (
                                <>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleReportClick(postUserListLike.uuid)
                                    }
                                  >
                                    Report
                                  </Dropdown.Item>
                                </>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        {isShowCommentBox &&
                          postUserListLike.post_comments.length > 0 &&
                          postUserListLike.post_comments
                            .reverse()
                            .map((commnetVal, index) => (
                              <>
                                <div
                                  className="comment-listed"
                                  style={{
                                    minHeight: "50%",
                                    overflow: "scroll",
                                  }}
                                >
                                  <div className="user-comment">
                                    <Image
                                      src={
                                        commnetVal.user__photo_url
                                          ? commnetVal.user__photo_url
                                          : userImage
                                      }
                                      // width="50"
                                      // height="50"
                                      //style={{borderRadius:'50%'}}
                                    />
                                    <div className="single-comment">
                                      <div>
                                      <p>{`${commnetVal.user__first_name} ${commnetVal.user__last_name}`}</p>
                                      <p>{commnetVal.comment}</p>
                                      </div>
                                      <div>
                                          {moment
                                            .utc(commnetVal.created_at)
                                            .local()
                                            .format("DD/MM/YYYY hh:mm a")}
                                        </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}

                        <div className="comment-input-send">
                          <Form.Input
                            fluid
                            type="text"
                            placeholder="Comment here...."
                            value={
                              commentInput && commentInput ? commentInput : ""
                            }
                            onChange={(e) => handleInputComment(e)}
                            disabled={isDisableInput ? true : false}
                            name="comment"
                            autoFocus={true}
                            onKeyPress={(e) =>
                              handleKeypress(e, postUserListLike.uuid, "like")
                            }
                            // maxLength={50}ss
                          />
                          <Icon
                            name="send"
                            onClick={() =>
                              handleAddComment(postUserListLike.uuid)
                            }
                          />
                        </div>
                        <hr className="horigental-line" />
                      </>
                    </div>
                  ) : (
                    <div>
                      <Header as="h3" textAlign="center">
                        No record found
                      </Header>
                    </div>
                  )}
                </div>
              </Grid.Column>
            </Grid>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default withRouter(PostDetails);
