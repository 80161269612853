import React from "react";
import "semantic-ui-css/semantic.min.css";
import "../Login/Login.scss";
import { toast } from "react-toastify";
import { Image, Icon, Header } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import "../../App.scss";
import userImage from "../../assets/img/user-image.png";
function TrandingDislikePost({
  content = "",
  why_content = "",
  content_type = "",
  media_url = "",
  item = "",
}) {
  const handleReactionClick = () => {
    toast.success("Please register or login to engage", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const check5Minute = (date) => {
    var result = timeDifference(new Date(), new Date(date));
    if (result.includes("seconds ago")) {
      return false;
    } else if (result.includes("minutes ago")) {
      let firstWord = result.split(" ")[0];
      var check = parseInt(firstWord.trim());
      if (check > 4) {
        return true;
      } else {
        return false;
      }
    } else if (!result.includes("seconds ago")) {
      return true;
    } else {
      return false;
    }
  };
  const timeDifference = (current, previous) => {
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;
    var elapsed = current - previous;
    if (elapsed < msPerMinute) {
      return Math.round(elapsed / 1000) + " seconds ago";
    } else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + " minutes ago";
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + " hours ago";
    } else if (elapsed < msPerMonth) {
      if (Math.round(elapsed / msPerDay) > 1) {
        return Math.round(elapsed / msPerDay) + " days ago";
      } else {
        return Math.round(elapsed / msPerDay) + " day ago";
      }
    } else if (elapsed < msPerYear) {
      if (Math.round(elapsed / msPerMonth) > 1) {
        return Math.round(elapsed / msPerMonth) + " months ago";
      } else {
        return Math.round(elapsed / msPerMonth) + " month ago";
      }
    } else {
      if (Math.round(elapsed / msPerYear) > 1) {
        return Math.round(elapsed / msPerYear) + " years ago";
      } else {
        return Math.round(elapsed / msPerYear) + " year ago";
      }
    }
  };
  return (
    <>
      <div className="why-parent-div" style={{ backgroundColor: "#bf1414" }}>
        <div className="content">
          <div
            className="image-name-user"
            style={{
              cursor: "pointer",
              width: "95%",
              display:"flex",
              justifyContent:"space-between"
            }}
          >
            <div style={{display:"flex",alignItems:"center",justifyContent:"start"}}>
              <Image
                src={item.photo_url ? item.photo_url : userImage}
                width="50"
                height="50"
              />
              <div>
              <Header as="h5" style={{ marginBottom: 0, color: "white" }}>
                {item.user
                  ? `@${item.user}`
                  : `N/A`}
              </Header>
              <Header as="h5" style={{ marginTop: 0, color: "white" }}>
                {item.first_name || item.last_name
                  ? `${item.first_name} ${item.last_name}`
                  : `N/A`}
              </Header>
              </div>
            </div>

            <div className="image-name-user">
              <Header as="h5" className="font-italic" style={{color:"white"}}>
                {check5Minute(item.created_at) ? "" : "is currently"} Dislikes:
              </Header>
            </div>
          </div>
          <p style={{ color: "#fff" }}>{content}</p>
          {content_type === "video" && (
            <div>
              <video style={{ width: "100%", maxHeight: "300px" }} controls>
                <source src={media_url} type="video/mp4" />
              </video>
            </div>
          )}
          {(content_type === "photo" ||
            content_type === "image" ||
            content_type === "gif") && (
            <div>
              <Image
                style={{ maxHeight: "300px", width: "100%" }}
                src={media_url}
              />
            </div>
          )}
          <div style={{display:'flex',justifyContent:"space-between",alignItems:"center"}}>
          <div className="left-line"></div>
          <div className="why-word">Why?</div>
          <div className="right-line"></div>
          </div>
          <p style={{ color: "#fff" }}>{why_content}</p>
          <div
            className="text-right public-reaction-icon-content"
            style={{ color: "white", cursor: "pointer" }}
            onClick={handleReactionClick}
          >
            <Icon
              name={item.is_favourite ? "heart" : "heart outline"}
              className="public-reaction-icon"
            />
            <span className="count-user-reaction">
              ({item.favourite_count})&nbsp;&nbsp;
            </span>
            <Icon
              name={item.is_like ? "thumbs up" : "thumbs up outline"}
              className="public-reaction-icon"
            />
            <span className="count-user-reaction">
              ({item.like_count})&nbsp;&nbsp;
            </span>
            <Icon
              name={item.is_dislike ? "thumbs down" : "thumbs down outline"}
              className="public-reaction-icon"
            />
            <span className="count-user-reaction">
              ({item.dislike_count})&nbsp;&nbsp;
            </span>
            <Icon
              name={item.is_comment ? "comment" : "comment outline"}
              className="public-reaction-icon"
            />
            <span className="count-user-reaction">
              ({item.comments_count})&nbsp;&nbsp;
            </span>
            <Icon name="share square" className="public-reaction-icon" />
            <span className="count-user-reaction"></span>
            <Icon name={"eye"} className="public-reaction-icon" />
            <span className="count-user-reaction">
              ({item.seen_count})&nbsp;&nbsp;
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
export default withRouter(TrandingDislikePost);
