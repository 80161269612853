import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AboutUS from "../FooterLinks/AboutUs/AboutUs";
import CommunityGuideLine from "../FooterLinks/AboutUs/CommunityGuideLine";
import ContactUS from "../FooterLinks/AboutUs/ContactUs";
import Cookies from "../FooterLinks/AboutUs/Cookies";
import Help from "../FooterLinks/AboutUs/Help";
import InviteFriend from "../FooterLinks/AboutUs/InviteFriend";
import PrivacyPolicy from "../FooterLinks/AboutUs/PrivacyPolicy";
import PrivacySetting from "../FooterLinks/AboutUs/PrivacySetting";
import ForgotPassword from "../Login/ForgotPassword";
import ResetPassword from "../Login/ResetPassword";
import Login from "../Login/Login";
import { Paths } from "./routePaths";
import BlockedUserList from "../FooterLinks/AboutUs/BlockedUserList";
import Profile from "../FooterLinks/AboutUs/Profile";
import Home from "../Home/Home";
import Favourite from "../Home/Favourite";
import ProfileSetting from "../FooterLinks/AboutUs/ProfileSetting";
import Following from "../FooterLinks/AboutUs/Following";
import CreatePost from "../Home/CreatePost";
import Follower from "../FooterLinks/AboutUs/Followers";
import Notifications from "../FooterLinks/AboutUs/Notifications";
import NotificationSettings from "../FooterLinks/AboutUs/NotificationSettings";
//import PrivateRoute from "./PrivateRoute";
import { ToastContainer } from "react-toastify";
import PublicReactions from "../Home/PublicReactions";
import UserListChat from "../Chating.js/UserListChat";
import { UserContext } from "../../context/UserContext";
import Friends from "../FooterLinks/AboutUs/Friends";
import PostDetails from "../Home/PostDetails";
import TermsCondition from "../FooterLinks/AboutUs/TermsCondition";
import NewPassword from "../Login/NewPassword";
import ChangePassword from "../FooterLinks/AboutUs/ChangePassword";
import Activate from "../Login/Activate";

function Routes() {
  let email = localStorage.getItem("email");
  const { isLogedIn } = useContext(UserContext);
  return (
    <Router>
      <ToastContainer />
      <Switch>
       <Route exact path={Paths.NewPassword} component={NewPassword} />
        <Route exact path={Paths.LogIn} component={Login} />
        <Route exact path={Paths.ResetPassword} component={ResetPassword} />
        <Route exact path={Paths.ForgotPassword} component={ForgotPassword} />
        <Route
          exact
          path={Paths.CommunityGuideLine}
          component={CommunityGuideLine}
        />
        <Route exact path={Paths.AboutUS} component={AboutUS} />
        <Route exact path={Paths.Cookies} component={Cookies} />
        <Route exact path={Paths.ContactUS} component={ContactUS} />
        <Route exact path={Paths.PrivatePolicy} component={PrivacyPolicy} />
        <Route exact path={Paths.ActivateAccount} component={Activate} />
        <Route exact path={Paths.TermCondition} component ={TermsCondition} />
        {email || isLogedIn ? (
          <>
            <Route exact path={Paths.Help} component={Help} />

            <Route exact path={Paths.InviteFriend} component={InviteFriend} />
            <Route
              exact
              path={Paths.PrivacySetting}
              component={PrivacySetting}
            />
            <Route exact path={Paths.BlockedUser} component={BlockedUserList} />
            <Route exact path={Paths.ProfileSetUp} component={Profile} />
            {<Route exact path={Paths.ChangePassword} component={ChangePassword} /> }
            <Route exact path={Paths.UserProfileDetail} component={Home} />
            <Route exact path={Paths.PostDetails} component={PostDetails} />
          

            <Route
              exact
              path={Paths.PublicReactions}
              component={PublicReactions}
            />
            {/* <Route exact path={Paths.Favourite} component={Favourite} /> */}
            <Route exact path={Paths.Favourite_id} component={Favourite} />
            <Route
              exact
              path={Paths.ProfileSetting}
              component={ProfileSetting}
            />
            <Route exact path={Paths.Following} component={Following} />
            <Route exact path={Paths.CreatePost} component={CreatePost} />
            <Route exact path={Paths.Follower} component={Follower} />
            <Route exact path={Paths.Friends} component={Friends} />
            <Route
              exact
              path={Paths.NotificationSettings}
              component={NotificationSettings}
            />
            <Route exact path={Paths.Notifications} component={Notifications} />
            <Route exact path={Paths.UpdatePost} component={CreatePost} />
            <Route
              exact
              path={Paths.FollowingOtherUserDetail}
              component={Following}
            />
            <Route
              exact
              path={Paths.FollowerOtherUserDetail}
              component={Follower}
            />
            <Route exact path={Paths.UserChat} component={UserListChat} />
            {/* <Route
              exact
              path={Paths.CommunityGuideLine}
              component={CommunityGuideLine}
            /> */}
            {/* <Route exact path={Paths.AboutUS} component={AboutUS} />
            <Route exact path={Paths.Cookies} component={Cookies} />
            <Route exact path={Paths.ContactUS} component={ContactUS} />
            <Route exact path={Paths.PrivatePolicy} component={PrivacyPolicy} /> */}
          </>
        ) : (
          <Redirect to={"/"}></Redirect>
        )}
        
      </Switch>
    </Router>
  );
}
export default Routes;
