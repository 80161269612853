import React, { useState, useEffect } from "react";
import "semantic-ui-css/semantic.min.css";
import { Button, Form, Header, Image,Icon} from "semantic-ui-react";

import "../../App.scss";
import Footer from "../FooterLinks/Footer";
import "../FooterLinks/AboutUs/about-us.scss";
import "./Login.scss";
import { authServices } from "../../Services/Auth";
import { toast } from "react-toastify";

export default function ResetPassword(props) {
  // const [activeItem, setActiveItem] = useState("home")
  const [Valid, setValid] = useState(false);
  const [Code, setCode] = useState("");
  const [Newpassword, setNewpassword] = useState("");
  const [Show, setShow] = useState(true);
  const [ShowConfirm, setShowConfirm] = useState(true);
  const [passwordError, setPasswordErr] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const handleCodeSend = (e) => {
    setCode(e.target.value);
  };
  const handleNewPassword = (event) => {
    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp = /.{8,}/;
    const passwordLength = event.target.value.trim().length;
    const uppercasePassword = uppercaseRegExp.test(event.target.value.trim());
    const lowercasePassword = lowercaseRegExp.test(event.target.value.trim());
    const digitsPassword = digitsRegExp.test(event.target.value.trim());
    const specialCharPassword = specialCharRegExp.test(
      event.target.value.trim()
    );
    const minLengthPassword = minLengthRegExp.test(event.target.value.trim());
    let errMsg = "";
    if (passwordLength === 0) {
      errMsg = "Password is empty";
    } else if (!uppercasePassword) {
      errMsg = "At least one Uppercase";
    } else if (!lowercasePassword) {
      errMsg = "At least one Lowercase";
    } else if (!digitsPassword) {
      errMsg = "At least one digit";
    } else if (!specialCharPassword) {
      errMsg = "At least one Special Characters";
    } else if (!minLengthPassword) {
      errMsg = "At least minumum 8 characters";
    } else {
      errMsg = "";
    }

    setPasswordErr(errMsg);
    setNewpassword(event.target.value);
  };
  const handleSubmitOtp = () => {
    if (props.location.state.userEmail) {
      let payload = {
        email: props.location.state.userEmail,
        validation_type: "forgot password",
        code: Code.trim(),
      };
      authServices.verifyCode(payload).then((data) => {
        if (data.status) {
          setCode(data.data.otp_code);
          setValid(true);
        } else {
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      },(error)=>{
        toast.error("Incorrect code", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    }
  };
  const handleRestPassword = () => {
    if (props.location.state.userEmail) {
      let payload = {
        email: props.location.state.userEmail,
        code: Code,
        password: Newpassword.trim(),
      };
      authServices.resetPassword(payload).then((data) => {
        toast.success(data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        props.history.push("/");
      },(error)=>{
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    }
  };

  //
  // const handleForgetPassword=()=>{
  //   const { name, value } = e.target;
  //   setProfileDetail({
  //     ...profileDetail,
  //     [name]: value,
  //   });
  // }

  return (
    <div className="login-page">
      <div className="header-login about-us-header">
        <div>
          <Image
            src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1601659221875x962391620398017200%2Flogo-02.png?w=256&h=108&auto=compress&fit=crop&dpr=1"
            width="210"
            height="80"
          />
        </div>
      </div>
      <div className="main-content" style={{ marginBottom: "20%" }}>
        <Header as="h3" textAlign="center">
          Reset password:
        </Header>
        {!Valid && (
          <div className="forgot-pwd-form">
            <Form onSubmit={handleSubmitOtp}>
              <Form.Input
                label="Enter code"
                type="text"
                placeholder="Enter code"
                className="input-email-forgot"
                required
                onChange={handleCodeSend}
                value={Code}
              />
              <Button className="send-contact-us forgot-pwd-send" type="submit">
                Send Code
              </Button>
            </Form>
          </div>
        )}
        {Valid && (
          <div className="forgot-pwd-main">
            <div className="forgot-pwd-form">
              <Form onSubmit={handleRestPassword}>
                <Form.Input
                  type={!Show?"text":"password"}
                  placeholder="Enter New Password"
                  className="input-email-forgot"
                  required
                  onChange={handleNewPassword}
                  value={Newpassword}
                  error={passwordError ? true:false}
                />
                 <Icon
                  name={Show ? "eye slash outline" : "eye"}
                  onClick={() => setShow(!Show)}
                  className="eye-icon-crfpass2"
                  style={{position:"absolute",top:"6%",left:"93%"}}
                  
                />
              <span style={{color:'red'}}>{passwordError}</span>

                <Button
                  className="send-contact-us forgot-pwd-send"
                  type="submit"
                >
                  Reset password
                </Button>
              </Form>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}
