import React, { useState, useEffect,useContext} from "react";
import { DmsContext, NotificationContext } from "../../context/NotificationContext";
import "semantic-ui-css/semantic.min.css";
// import "../../Login/Login.scss";
import {
  Button,
  Grid,
  Header,
  Segment,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import "../../App.scss";
import Footer from "../FooterLinks/Footer";
import "../FooterLinks/AboutUs/about-us.scss";
import "./home.scss";
import {  withRouter, useHistory } from "react-router-dom";
//import MenuBar from "../FooterLinks/AboutUs/MenuBar";
import HeaderHome from "./HeaderHome";
// import userImage from "../../assets/img/user-image.png";
// import PublicLike from "../FooterLinks/PublicLike";
// import PublickDislike from "../FooterLinks/PublickDislike";
//import { HomeServices } from "../../Services/HomeServices";
import PostListingLikeDislike from "./PostListingLikeDislike";
import { UserProfileServices } from "../../Services/UserProfileServices";

const userProfileServices = new UserProfileServices();

function PublicReactions(props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [followingfDetail, setFollowingfDetail] = useState(false);
  const [profileStatus, setprofileStatus] = useState(true)
  const { setCountNotification } = useContext(NotificationContext);
  const {setDmsCount} =useContext(DmsContext);
  const history = useHistory();

  useEffect(() => {
    setIsLoading(true);
    getUserFollowersOrFollowing();
    getAccountInfo()
  }, []);

  const handleClickFriends = (e) => {
    history.push("/friends");
  };

  const getAccountInfo =()=>{
    userProfileServices.getUserProfileDetail().then(
      (data)=>{
        setprofileStatus(data.is_activated)
      },
      (error)=>{
       
      }
    )

  }

  const getUserFollowersOrFollowing = () => {
    setIsLoading(true);
    userProfileServices.getUserFollowersOrFollowing().then(
      (data) => {
        setFollowingfDetail(data.data);
        setCountNotification(data.data.count)
        setDmsCount(data.data.dm_count)
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
      
      }
    );
  };

  return (
    <>
    <div className="login-page" >
      <HeaderHome />
      {!profileStatus && <div className="activate-bar">An email has been sent to you, please activate your account</div>} 

      <div className="main-content" style={{ height: "100vh" }}>
        <Header as="h3">Public Moods</Header>
        <Segment className="loader-custom">
          <Dimmer active={isLoading} inverted>
            <Loader inverted content="Loading" />
          </Dimmer>
        </Segment>

        <div className="btn-create-reaction-followers-following">
          <Grid>
            <Grid.Column mobile={8} tablet={8} computer={3}>
              <Button
                className="send-contact-us"
                onClick={() => props.history.push("/create-post")}
                style={{
                  borderRadius: 4,
                  padding: 10,
                  marginLeft: 0,
                  marginTop: 40,
                  width: "150px",
                }}
              >
                Post Mood
              </Button>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={8} computer={3}>
              <Button
                className="send-contact-us"
                style={{
                  borderRadius: 4,
                  padding: 10,
                  marginLeft: 0,
                  marginTop: 40,
                  width: "150px",
                }}
                onClick={() => props.history.push(`/user-profile/${localStorage.getItem("uid")}`)}
              >
                My Moods
              </Button>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={8} computer={3}>
              <Button
                className="send-contact-us"
                style={{
                  borderRadius: 4,
                  padding: 10,
                  marginLeft: 0,
                  marginTop: 40,
                  width: "150px",
                }}
                onClick={() => props.history.push("/followers")}
              >
                Followers (
                {followingfDetail ? followingfDetail.followers.length : 0})
              </Button>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={8} computer={3}>
              <Button
                className="send-contact-us"
                style={{
                  borderRadius: 4,
                  padding: 10,
                  marginLeft: 0,
                  marginTop: 40,
                  width: "150px",
                }}
                onClick={() => props.history.push("/following")}
              >
                Followings (
                {followingfDetail ? followingfDetail.following.length : 0})
              </Button>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={8} computer={3}>
              <Button
                className="send-contact-us"
                style={{
                  borderRadius: 4,
                  padding: 10,
                  marginLeft: 0,
                  marginTop: 40,
                  width: "150px",
                }}
                // onClick={() => props.history.push("/following")}
                onClick={() => handleClickFriends()}
              >
                Follow Requests
              </Button>
            </Grid.Column>
          </Grid>
        </div>
        <PostListingLikeDislike />
      </div>
   
    </div>
        <Footer /> 
       </>
  );
}
export default withRouter(PublicReactions);
