import React, { useState, useEffect } from "react";
import "semantic-ui-css/semantic.min.css";
// import "../../Login/Login.scss";
import {
  Button,
  Header,
  Image,
} from "semantic-ui-react";
//import { Icon } from "semantic-ui-react";
import "../../../App.scss";
import Footer from "../Footer";
import "./about-us.scss";
import LogoHeader from "./LogoHeader";
import { UserProfileServices } from "../../../Services/UserProfileServices";
import { toast } from "react-toastify";

const userProfileServices = new UserProfileServices();

export default function Following(props) {
 
  const [FollowingList, setFollowingList] = useState([]);
 // const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
      getUserFollowersOrFollowing();
    
  }, []);

  const getUserFollowersOrFollowing = () => {
    let id = "";
    let uid = localStorage.getItem("uid");
    if (props.match.params.id) {
      id = props.match.params.id;
    } else {
      id = uid;
    }
  
    userProfileServices.getUserFollowersOrFollowing(id).then(
      (data) => {
      
        setFollowingList(data.data.following);
     
      },
      (error) => {
       
       
      }
    );
  };

  const handleClickUnFollow = (id) => {
 
    userProfileServices.handleClickUnFollow(id, "following").then(
      (data) => {
    
        getUserFollowersOrFollowing();
        toast.success(data.message ? data.message : data.response, {
          position: toast.POSITION.TOP_RIGHT,
        });
      },
      (error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      
        
      }
    );
  };
  const handleProfileOpen=(id)=>{
    props.history.push(`/user-profile/${id}`)
  }
  return (
    <>
    <div className="login-page" style={{marginBottom:"20%"}}>
      <LogoHeader />
      {/* <Segment className="loader-custom">
        <Dimmer active={isLoading} inverted>
          <Loader inverted content="Loading" />
        </Dimmer>
      </Segment> */}
      <div className="main-content">
        <Header as="h3">Following</Header>
        {FollowingList.length > 0 ? (
          FollowingList.map((item) => (
            
              <div
                className="div-inline"
                style={{ marginBottom: 15, display: "flex" }}
              >
            
                <div
                  className="div-inline"
                  style={{ width: "70%", display: "flex",cursor:"pointer" }}
                  onClick={()=>handleProfileOpen(item.uuid)}
                >
                  <div className="img-block-user">
                    <div className="div-inline img-div-follow">
                      <Image
                        src={
                          item.photo_url
                            ? item.photo_url
                            : "https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"
                        }
                        width="100"
                        height="100"
                        style={{borderRadius:'50px'}}
                      />
                    
                    </div>
                  </div>
                  <p className="div-inline text-block-user">
                   
                    <Header as="h3">
                      {item.username}
                    </Header>
                    <Header as="h5" style={{ color: "#838392", margin: 0 }}>
                      {item.email}
                    </Header>
                  </p>
                </div>
                <div className="div-inline" style={{ width: "30%" }}>
                {props.match.params && props.match.params.id ? null:<Button
                    className="send-contact-us"
                    style={{ borderRadius: 4, padding: 10 }}
                    onClick={() => handleClickUnFollow(item.uuid)}
                  >
                    Unfollow
                  </Button>}
                </div>
              </div>
            
           ))
        ) : (
          <div>
            <Header as="h3" textAlign="center">
              No record found
            </Header>
          </div>
        )} 
      </div>
   
    </div>
       <Footer />
       </>
  );
}
