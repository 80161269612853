import React,{useState} from "react";
import "semantic-ui-css/semantic.min.css";
import { toast } from "react-toastify";

// import "../../Login/Login.scss";
import { Button, Form, Header } from "semantic-ui-react";
import "../../../App.scss";
import Footer from "../Footer";
import "./about-us.scss";
import LogoHeader from "./LogoHeader";
import { authServices } from "../../../Services/Auth";

export default function ContactUS(props) {
  // const [activeItem, setActiveItem] = useState("home")
  const [submitted, setSubmitted] = useState(false);
  const [Email, setEmail] = useState("");
  const [Name, setName ]= useState("")
  const [Message, setMessage ]= useState("")
  const [disableSubmit, setDisableSubmit] = useState(false);
 

  const handleContactUs = (e) => {
    e.preventDefault();
    if (Email && Name && Message) {
      var contact = {
        email: Email,
        name: Name,
        message:Message
      };
      setDisableSubmit(true);
      authServices.contactUs(contact).then(
        (data) => {
          setDisableSubmit(false);
          toast.success("Your message sent successfuly", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setEmail("")
          setMessage("")
          setName("")

          
        },
        (error) => {
          setDisableSubmit(false);
          toast.error("Error sending message", {
            position: toast.POSITION.TOP_RIGHT,
          });
          
        }
      );
    } else {
      setSubmitted(true);
    }
  };

  return (
    <>
      <div className="login-page"  style={{paddingBottom:"40px",height:"auto" }}>
        <LogoHeader />

        <div className="main-content" style={{ marginBottom: "20%" }}>
          <Header as="h3" textAlign="center">
            Contact Us
          </Header>
          <p>
            We are always happy to hear from both our users and also non-users
            of Moodflik.com; should you have any questions relating to this
            platform, questions for staff members, requests from the press, any
            form of dissatisfaction in using our platform, feeling the need to
            report abuse or an abuser discovered here or perhaps just wishing to
            provide us with feedback on your experiences in using either our web
            or mobile application or friendly and technical suggestions of what
            we can do to continue to improve our platform.
          </p>
          <p>
            For whatever the reason, please do not hesitate to contact us with
            the form below, or you may send us an email on{" "}
            <a href="info@moodflik.com">info@moodflik.com</a> and a member of our team will
            endeavour to get back to you as soon as possible.
          </p>
          <p>Thanks,</p>
          <p>The Team at Moodflik</p>
          <div style={{ marginLeft: 30, marginRight: 30 }}>
            <Form>
              <Form.Group widths="equal">
              <Form.Input
                   label="Name"
                    fluid
                    placeholder="Type name..."
                    value={Name}
                    onChange={(e) => setName(e.target.value)}
                    error={submitted && !Name ? true : false}
                  />
                  <Form.Input
                  label="Email"
                    fluid
                    placeholder="Type email..."
                    value={Email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={submitted && !Email ? true : false}
                  />
              </Form.Group>
              <Form.TextArea 
               fluid
               placeholder="Type message..."
               value={Message}
               onChange={(e) => setMessage(e.target.value)}
               error={submitted && !Message ? true : false}/>
              <Button className="send-contact-us"
               onClick={handleContactUs}
               disabled={disableSubmit}>Send</Button>
            </Form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
