import "../components/Sys/config";
import axios from "axios";
var apiUrl = `${process.env.REACT_APP_BASE_URL}`;


export const authServices = {
  userLogin,
  forgotPassword,
  resetPassword,
  verifyCode,
  contactUs,
  userSignUp,
  getTredingPostOnLoginPageDisLike,
  getTredingPostOnLoginPageLike,
};
//Reset password
function resetPassword(payload){
  //return axios.patch(apiUrl + "",payload).then((res) => res.data);
  var requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };

  return fetch(apiUrl + "forget_password/", requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}
//Verify code
function verifyCode(payload){
  
  //return axios.post(apiUrl + "validate-otp/",payload).then((res) => res.data);
  var requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };

  return fetch(apiUrl + "validate-otp/", requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
 
}

//forget password 
function forgotPassword(payload){
 // return axios.post(apiUrl + "",payload).then((res) => res.data);
  var requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };

  return fetch(apiUrl + "user/request_forgotpassword/", requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function userLogin(payload) {
  var requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };

  return fetch(apiUrl + "user/authorize/", requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function getTredingPostOnLoginPageDisLike() {
  var requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetch(apiUrl + "api/most_seen/?type=dislike", requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}
function getTredingPostOnLoginPageLike() {
  var requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetch(apiUrl + "api/most_seen/?type=like", requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function userSignUp(payload) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
 
  return fetch(apiUrl + "user/", requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}
function contactUs(payload) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
 
  return fetch(apiUrl + "contact_us/", requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // logout();
      }

      const error = data || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
