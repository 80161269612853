import React, { useEffect, useState } from "react";
import "./Login.scss";
import { Icon } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import "./Login.scss";
import { Button, Form, Header } from "semantic-ui-react";
import { withRouter, Link } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

function NewPassword() {
  const [Password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [Show, setShow] = useState(false);
  const [Show2, setShow2] = useState(false);
  const search = useLocation().search;
  const uidb64 = new URLSearchParams(search).get("uidb64");
  const token = new URLSearchParams(search).get("token");
  const [ShowSuccessMsg, setShowSuccessMsg] = useState(false);
  const Expire = new URLSearchParams(search).get("token_valid");

  const onSubmit = () => {
    const payload = {
      password: Password,
      password2: ConfirmPassword,
      token: token,
      uidb64: uidb64,
    };
    console.log("Payload", payload);
    // axiosForgetPasswordInstance
    //   .patch("/users/password_reset/confirm/", values)
    //   .then((res) => {
    //     setShowSuccessMsg(true);
    //   })
    //   .catch((error) => {
    //     console.log("error");
    //   });
  };

  const handleInputChange = (e) => {
    setPassword(e.target.value);
  };
  const handleConfirmInputChange = (e) => {
    setConfirmPassword(e.target.value);
  };
  return (
    <>
      <div className="login-page">
        {!ShowSuccessMsg ? (
          <>
            {Expire == "True" ? (
              <div className="main-content" style={{ marginBottom: "20%" }}>
                <Header as="h3">Enter your email</Header>
                <Form>
                  <Form.Input
                    className="my-input"
                    fluid
                    type={Show ? "password" : "input"}
                    name="password"
                    minLength={8}
                    placeholder="Password"
                    value={Password}
                    onChange={(e) => handleInputChange(e)}
                  />
                  <Icon
                    name={Show ? "eye slash outline" : "eye"}
                    onClick={() => setShow(!Show)}
                    style={{ position: "absolute", left: "59%", top: "26%" }}
                  />
                  <Form.Input
                    className="my-input"
                    fluid
                    type={Show ? "password" : "input"}
                    name="password"
                    minLength={8}
                    placeholder="Password"
                    value={ConfirmPassword}
                    onChange={(e) => handleConfirmInputChange(e)}
                  />
                  <Icon
                    name={Show ? "eye slash outline" : "eye"}
                    onClick={() => setShow2(!Show2)}
                    style={{ position: "absolute", left: "59%", top: "26%" }}
                  />
                  <Button
                    onClick={onSubmit}
                    className="send-contact-us forgot-pwd-send"
                  >
                    Send
                  </Button>
                </Form>
              </div>
            ) : (
              <div>Your password verification is expired</div>
            )}
          </>
        ) : (
          <div>Password succesfully changed</div>
        )}
      </div>
    </>
  );
}

export default NewPassword;
