import React,{useEffect,useState} from "react";
import "semantic-ui-css/semantic.min.css";
import { Button, Grid, Header} from "semantic-ui-react";
import "../../App.scss";
import Footer from "../FooterLinks/Footer";
import '../FooterLinks/AboutUs/about-us.scss'
import { useHistory } from "react-router-dom";
import './home.scss'
import { withRouter } from "react-router-dom";
import { UserProfileServices } from "../../Services/UserProfileServices";
import HeaderHome from "./HeaderHome";
import PostListingLikeDislike from "./PostListingLikeDislike";
const userProfileServices = new UserProfileServices();



function Favourite(props) {

    const [followingfDetail, setFollowingfDetail] = useState(false);
    const [isProfileId, setIsProfileId] = useState("");
    const history= useHistory()
    useEffect(() => {
        getUserFollowersOrFollowing(props.match.params.id)
        setIsProfileId(props.match.params.id);
    }, [props.match.params.id])
    const getUserFollowersOrFollowing = (id) => {
       
        userProfileServices.getUserFollowersOrFollowing(id ? id : "").then(
          (data) => {
            setFollowingfDetail(data.data);
         
          },
          (error) => {
          
            
          }
        );
      };
      const handleClickFollowers = (e) => {
        if (isProfileId) {
          return history.push(`/followers/${isProfileId}`);
        } else {
          return history.push("/followers");
        }
      };
      const handleClickFollowing = (e) => {
        if (isProfileId) {
          return history.push(`/following/${isProfileId}`);
        } else {
          return history.push("/following");
        }
      };
    return (
        <>
        <div className="login-page" style={{marginBottom:"20%"}}>
            <HeaderHome />

            <div className="main-content margin-top-100" style={{ height: "100vh" }}>
            <Header as="h3">
                   My Favourites
                </Header>
                <div className="btn-create-reaction-followers-following">
                    <Grid>
                        <Grid.Column mobile={8} tablet={8} computer={3}>
                            <Button className="send-contact-us"
                                onClick={() => props.history.push("/create-post")}
                                style={{ borderRadius: 4, padding: 10, marginLeft: 0, marginTop: 40, width: "150px" }}>
                                Post Mood
                            </Button>
                        </Grid.Column>
                        <Grid.Column mobile={8} tablet={8} computer={3}>
                            <Button className="send-contact-us" style={{ borderRadius: 4, padding: 10, marginLeft: 0, marginTop: 40, width: "150px" }}
                                onClick={() => props.history.push("/public-reactions")}

                            >
                                Public Moods
                            </Button>
                        </Grid.Column>
                        <Grid.Column mobile={8} tablet={8} computer={3}>
                            <Button className="send-contact-us" style={{ borderRadius: 4, padding: 10, marginLeft: 0, marginTop: 40, width: "150px" }} onClick={() => props.history.push(`/user-profile/${props.match.params.id}`)}>
                                My Moods
                            </Button>
                        </Grid.Column>
                       
                        <Grid.Column mobile={8} tablet={8} computer={3}>
                        <Button
                        className="send-contact-us"
                        style={{
                            borderRadius: 4,
                            padding: 10,
                            marginLeft: 0,
                            marginTop: 40,
                            width: "150px",
                        }}
                        // onClick={() => props.history.push("/followers")}
                        onClick={() => handleClickFollowers()}
                        >
                        Followers (
                        {followingfDetail ? followingfDetail.followers.length : 0})
                        </Button>
                        </Grid.Column>
                        <Grid.Column mobile={8} tablet={8} computer={3}>
                            <Button
                            className="send-contact-us"
                            style={{
                                borderRadius: 4,
                                padding: 10,
                                marginLeft: 0,
                                marginTop: 40,
                                width: "150px",
                            }}
                            // onClick={() => props.history.push("/following")}
                            onClick={() => handleClickFollowing()}
                            >
                            Followings (
                            {followingfDetail ? followingfDetail.following.length : 0})
                            </Button>
                        </Grid.Column>
                    </Grid>
                </div>
                <div>
                    <PostListingLikeDislike isFavourite={true} id={props.match.params.id} />
                </div>
            </div>
            
        </div>
        <Footer />
        </>
    );
}

export default withRouter(Favourite)
