import React, { useEffect, useState } from "react";
import "./App.scss";
import Routes from "../src/components/routes";
import { NotificationContext } from "../src/context/NotificationContext";
import { DmsContext } from "../src/context/NotificationContext";
import { UserContext } from "../src/context/UserContext";
import Footer from "./components/FooterLinks/Footer";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom";

function App() {
  const [Notification, setNotification] = useState([]);
  const [CountNotification, setCountNotification] = useState(0);
  const [DmsCount, setDmsCount] = useState(0)
  const [isLogedIn, setisLogedIn] = useState(false);

  let client = "";
  let socUrl = `${process.env.REACT_APP_SOCKET_URL}`;
  const connectNotificationClient = () => {
    client = new WebSocket(
      `${socUrl}ws/notification-server/${localStorage.getItem(
        "uid"
      )}/?token=${localStorage.getItem("ssoToken")}`
    );
    //client.addEventListener("open", openEventListener);
    client.addEventListener("message", messageLisntner);
  };

  let arr = [];
  function containsObject(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i] === obj) {
        return true;
      }
    }

    return false;
  }
  const messageLisntner = (data) => {
    let notification = JSON.parse(data.data);
    setDmsCount(notification.dm_count)
    setNotification((prev) => [...prev, notification]);
    setCountNotification(notification.count);
  };

  useEffect(() => {
    let Token = localStorage.getItem("ssoToken");
    if (Token) {
      setisLogedIn(true);
      setInterval(() => {
        if (!client) {
          connectNotificationClient();
        }
      }, 5000);
    } else {
      if (client) {
        client.close();
        console.log("CLOSED");
      }
    }
  }, [isLogedIn]);

  return (
   
      <NotificationContext.Provider
        value={{
          Notification,
          setNotification,
          setCountNotification,
          CountNotification,
        }}
      >
        <UserContext.Provider value={{ isLogedIn, setisLogedIn }}>
          <DmsContext.Provider value={{DmsCount,setDmsCount}}>
          <Routes  />
        
          </DmsContext.Provider>
        </UserContext.Provider>
      </NotificationContext.Provider>
    
  );
}

export default App;
