import React, { useState, useEffect } from "react";
import "semantic-ui-css/semantic.min.css";
// import "../../Login/Login.scss";
import {
  Button,
  Grid,
  Form,
  Header,
  Image,
  Icon,
  Segment,
  Dimmer,
  Loader
} from "semantic-ui-react";
import "../../App.scss";

import Footer from "../FooterLinks/Footer";
import "../FooterLinks/AboutUs/about-us.scss";
import "./home.scss";
import {  withRouter, useHistory } from "react-router-dom";
//import MenuBar from "../FooterLinks/AboutUs/MenuBar";
import HeaderHome from "./HeaderHome";
import { UserProfileServices } from "../../Services/UserProfileServices";
import PostListingLikeDislike from "./PostListingLikeDislike";
import { toast } from "react-toastify";
import { uploadFile } from "react-s3";
const userProfileServices = new UserProfileServices();

function Home(props) {
  //const [profileDetail, setProfileDetail] = useState(false);
  const [ IsLoading,setIsLoading] = React.useState(false);
  const [FavPostLikeCount, setFavPostLikeCount] = useState(0)
  const [FavPostDisLikeCount, setFavPostDisLikeCount] = useState(0)
  const [ ThingsLovePost,setThingsLovePost] = useState("");
  const [ ThingsDislikePost,setThingsDislikePost] = useState("");
  const [followingfDetail, setFollowingfDetail] = useState(false);
  const [isProfileId, setIsProfileId] = useState("");
  const [profileImage, setProfileImage] = React.useState(false);
  const [cover_photo_url, setCover_photo_url] = React.useState(false);
  const [UploadImage, setUploadImage] = useState(false)


  const [profileDetail, setProfileDetail] = useState({
    username: "",
    phone_number: "",
    first_name:"",
    last_name:"",
    country: "",
    city: "",
    website: "",
    me: "",
    like: "",
    is_bio:"",
    dislike: "",
    email:"",
  });
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    if (props.match.params && props.match.params.id) {
      getUserProfileDetail(props.match.params.id);
      getMyLikePost(props.match.params.id);
      getMyDislike(props.match.params.id);
      getUserFollowersOrFollowing(props.match.params.id);
      setIsProfileId(props.match.params.id);
      getFavLikePosts(props.match.params.id)  
      getFavDislikePosts(props.match.params.id)
    } 
   
    // else {
    //   getUserProfileDetail();
    //   getMyLikePost();
    //   getMyDislike();
    //   getUserFollowersOrFollowing();
    // }
  }, [props.match.params]);

  //get user profile details
  const getUserProfileDetail = (id) => {
    setIsLoading(true);
    userProfileServices.getUserProfileDetail(id).then(
      (data) => {
        setProfileDetail({
          username: data.username,
          phone_number: data.phone_number,
          first_name:data.first_name,
          last_name:data.last_name,
          country: data.country,
          city: data.city,
          website: data.website,
          me: data.me,
          like: data.like,
          dislike: data.dislike,
          email:data.email,
          is_bio:data.is_bio
        });
        setProfileImage(data.photo_url);
        setCover_photo_url(data.cover_photo_url);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        
      }
    );
  };
  //get user favourite like and dislike
  const getFavLikePosts=(id)=>{
    userProfileServices.getMyFavLikePost(id ? id : "").then(
      (data) => {
        setFavPostLikeCount(data.count)
      },
      (error) => {
        
    
      }
    );
  }
  const getFavDislikePosts=(id)=>{
    userProfileServices.getMyFavDisLikePost(id ? id : "").then(
      (data) => {
        setFavPostDisLikeCount(data.count)
      },
      (error) => {
        
    
      }
    );
  }
  //get users followers and followings
  const getUserFollowersOrFollowing = (id) => {
    setIsLoading(true);
    userProfileServices.getUserFollowersOrFollowing(id ? id : "").then(
      (data) => {
       setFollowingfDetail(data.data);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        
      }
    );
  };
  //get all user's post 
  const getMyLikePost = () => {
   let url
    if (props.match.path === "/user-profile/:id") {
      url = `api/my_posts/?type=like&user_id=${props.match.params.id}`;
      setIsLoading(true);
    userProfileServices.getMyLikePost(url).then(
      (data) => {
      
        setThingsLovePost(data.count);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
       
      }
    );
    }
    
  };
  const getMyDislike = () => {
    let url
    if (props.match.path === "/user-profile/:id") {
      url = `api/my_posts/?type=dislike&user_id=${props.match.params.id}`;
      setIsLoading(true);
      userProfileServices.getMyDislike(url).then(
        (data) => {
          setThingsDislikePost(data.count);
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
         
        }
      );
    }
   
  };

  const handleClickFollowers = (e) => {
    if (isProfileId) {
      return history.push(`/followers/${isProfileId}`);
    } else {
      return history.push("/followers");
    }
  };
  const handleClickFollowing = (e) => {
    if (isProfileId) {
      return history.push(`/following/${isProfileId}`);
    } else {
      return history.push("/following");
    }
  };
  const handleClickFriends = (e) => {
    if (isProfileId) {
      return history.push(`/friends/${isProfileId}`);
    } else {
      return history.push("/friends");
    }
  };

  //upload image to bucket
  const _handleUploadImage = (event, type) => {
    setUploadImage(true)
    setIsLoading(true)
    const config = {
      bucketName: "moodflik-portal",
      dirName: "img" /* optional */,
      region: "us-east-2",
      accessKeyId:process.env.REACT_APP_ACCESS_ID,
      secretAccessKey:process.env.REACT_APP_SECRET_KEY
    };
    const file = event.target.files[0];
    uploadFile(file, config)
      .then((data) => {
        if (type === "cover") {
          let cover=data.location.replace(/ /g, "%20")
          setCover_photo_url(cover);
          saveUserProfile(profileImage,cover)
        } else {
          let img=data.location.replace(/ /g, "%20")
          setProfileImage(img);
          saveUserProfile(img,cover_photo_url)
        }
      })
      .catch((err) => console.error(err));
  };
   // save user profile 
   const saveUserProfile=(profile,cover)=>{
    var data = {
      photo_url: profile,
      cover_photo_url: cover,
      ...profileDetail,
    };
   // setIsLoading(true);


    userProfileServices.userProfileUpdate(data).then(
      (data) => {
        setIsLoading(false);
        setUploadImage(false)
        //getUserProfileDetail();
        toast.success("Profile updated", {
          position: toast.POSITION.TOP_RIGHT,
        });
      },
      (error) => {
        setIsLoading(false);
        if (
          error.response.data &&
          error.response.data.username &&
          error.response.data.username[0]
        ) {
          toast.error(error.response.data.username[0], {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        setIsLoading(false);
        
      }
    );
   }
  return (
    <> 
      <div className="login-page" >
        <HeaderHome />
        <div className="main-content" >
        <Segment className="loader-custom">
          <Dimmer active={IsLoading} inverted>
            <Loader inverted content={`${UploadImage?"Uploading":"Loading"}`} />
          </Dimmer>
        </Segment>
          <Form style={{ position: "relative" }}>
            <div>
              <Image
                src={
                  cover_photo_url
                  ? cover_photo_url
                    : null
                }
                className="image-cover-photo"
              />
               {props.match.params.id==localStorage.getItem('uid')?<div className="image-upload-profile">
                    <React.Fragment>
                      <label
                        className="lable-for-file-input"
                        for="file-input-cover"
                      >
                        <Icon name="camera" />
                      </label>
                      <Form.Input 
                        id="file-input-cover"
                        type="file"
                        name="profile"
                        accept="image/jpeg,image/jpg"
                       onChange={(e) => _handleUploadImage(e, "cover")}
                      />
                    </React.Fragment>
                  </div>:null}
            </div>
            <div
              className="div-flex create-post-profile"
              style={{ marginBottom: 15 }}
            >
              <div className="div-flex image-cover-head">
                <div className="parent-image-cover">
                  <div class="image-upload-profile">
                    <Form enctype="multipart/form-data" id="user-form">
                      <Image
                        src={
                          profileImage
                                ? profileImage
                            : "https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"
                        }
                      />
                        {props.match.params.id==localStorage.getItem('uid')?<React.Fragment>
                            <label for="file-input">
                              <Icon name="camera" />
                            </label>
                            <Form.Input
                              id="file-input"
                              type="file"
                              name="profile"
                              accept="image/jpeg,image/jpg"
                              // style={{pointerEvents: this.props.isLoading==='isLoadingImageUpload' && 'none'}}
                             onChange={(e) => _handleUploadImage(e, "image")}
                            />
                          </React.Fragment>:null}
                    </Form>
                  </div>
                </div>

                {profileDetail.is_bio && <div className="form-detail-user-create-post">
                  <p>From</p>
                  <p style={{ fontWeight: 600, color: "#6C0AC7" }}>
                    Me, in 3 words: {profileDetail.me}
                  </p>
                  <p style={{ fontWeight: 600, color: "#108A07" }}>
                    Things I Love: {profileDetail.like}
                  </p>
                  <p style={{ fontWeight: 600, color: "#BF1414" }}>
                    Things I Dislike: {profileDetail.dislike}
                  </p>
                </div>}
              </div>

              <div className="div-inline like-dislike-button">
                <p style={{ textAlign: "center" }}>Favourites:</p>
                <div className="btn-like-dislike">
                  <Button
                    className="send-contact-us background-like-color"
                    style={{
                      borderRadius: 4,
                      padding: 10,
                      marginBottom: 10,
                      width: 100,
                    }}
                    onClick={()=>history.push(`/favourite/${props.match.params.id}`)}
                  >
                    Likes ({ FavPostLikeCount? FavPostLikeCount : 0})
                  </Button>
                  <Button
                    className="send-contact-us background-dislike-color"
                    style={{
                      borderRadius: 4,
                      padding: 10,
                      marginBottom: 10,
                      width: 100,
                    }}
                    onClick={()=>history.push(`/favourite/${props.match.params.id}`)}
                  >
                    Dislikes ({FavPostDisLikeCount ? FavPostDisLikeCount: 0})
                  </Button>
                </div>
              </div>
            </div>
          </Form>
          
          <div className="user-name-detail" style={{position:"relative"}}>
            <Header as="h3">
              {profileDetail.first_name} {profileDetail.last_name}
            </Header>
            <Header as="h5" style={{ color: "#838392" }}>
              @{profileDetail.username ? profileDetail.username : "N/A"}
            </Header>
          </div>
          <div className="btn-create-reaction-followers-following">
            <Grid>
             
              <Grid.Column mobile={8} tablet={8} computer={3}>
                <Button
                  className="send-contact-us"
                  onClick={() => props.history.push("/public-reactions")}
                  style={{
                    borderRadius: 4,
                    padding: 10,
                    marginLeft: 0,
                    marginTop: 40,
                    width: "150px",
                  }}
                >
                  Public Moods
                </Button>
              </Grid.Column>
              <Grid.Column mobile={8} tablet={8} computer={3}>
                <Button
                  className="send-contact-us"
                  style={{
                    borderRadius: 4,
                    padding: 10,
                    marginLeft: 0,
                    marginTop: 40,
                    width: "150px",
                  }}
                  // onClick={() => props.history.push("/followers")}
                  onClick={() => handleClickFollowers()}
                >
                  Followers (
                  {followingfDetail ? followingfDetail.followers.length : 0})
                </Button>
              </Grid.Column>
              <Grid.Column mobile={8} tablet={8} computer={3}>
                <Button
                  className="send-contact-us"
                  style={{
                    borderRadius: 4,
                    padding: 10,
                    marginLeft: 0,
                    marginTop: 40,
                    width: "150px",
                  }}
                  // onClick={() => props.history.push("/following")}
                  onClick={() => handleClickFollowing()}
                >
                  Followings (
                  {followingfDetail ? followingfDetail.following.length : 0})
                </Button>
              </Grid.Column>
              <Grid.Column mobile={8} tablet={8} computer={3}>
              <Button
                className="send-contact-us"
                onClick={() => history.push("/friends")}
                style={{
                  borderRadius: 4,
                  padding: 10,
                  marginLeft: 0,
                  marginTop: 40,
                  width: "150px",
                }}
              >
                Follow Requests
              </Button>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={8} computer={3}>
              <Button
                className="send-contact-us"
                onClick={() => history.push("/create-post")}
                style={{
                  borderRadius: 4,
                  padding: 10,
                  marginLeft: 0,
                  marginTop: 40,
                  width: "150px",
                }}
              >
                Post Mood
              </Button>
            </Grid.Column>
            </Grid>
          </div>
          <div>
          <PostListingLikeDislike isMyProfile={true} />
          </div>
       
        </div>
      </div>
       <Footer/> 
    </>
  );
}
export default withRouter(Home);
