import React, {  useState,useContext } from "react";
import "semantic-ui-css/semantic.min.css";
import "./Login.scss";
import { Icon } from "semantic-ui-react";
import { Button, Form, Header } from "semantic-ui-react";
import { withRouter, Link,useHistory } from "react-router-dom";
import "../../App.scss";
import { authServices } from "../../Services/Auth";
// import Footer from "../FooterLinks/Footer";
// import PublickDislike from "../FooterLinks/PublickDislike";
// import PublicLike from "../FooterLinks/PublicLike";
import { toast } from "react-toastify";
import { UserContext } from "../../context/UserContext";

function SignUp(props) {
  const [submitted, setSubmitted] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [isError, setIsError] = useState(false);
  const [Show, setShow] = useState(true);
  const [ShowConfirm, setShowConfirm] = useState(true);
  const [passwordError, setPasswordErr] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const history=useHistory()
  const { setisLogedIn } = useContext(UserContext);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    email: "",
    password: "",
    confirm_password: "",
    username:"",
    gender: "",
    terms_confirmed: false,
  });
  const options = [
    { key: "m", text: "Male", value: "M" },
    { key: "f", text: "Female", value: "F" },
    //{ key: "o", text: "Prefer not to say", value: "o" },
  ];

  const handleInputChange = (event) => {
    setIsError(false);
    // if(event.target.name==="username"){
    //   let val=event.target.value
    //   if(val.include("@")){
    //     setIsError(true)
    //   }
    // }
    if(event.target.name==='password'){
      const uppercaseRegExp   = /(?=.*?[A-Z])/;
      const lowercaseRegExp   = /(?=.*?[a-z])/;
      const digitsRegExp      = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp   = /.{8,}/;
      const passwordLength =      event.target.value.trim().length;
      const uppercasePassword =   uppercaseRegExp.test(event.target.value.trim());
      const lowercasePassword =   lowercaseRegExp.test(event.target.value.trim());
      const digitsPassword =      digitsRegExp.test(event.target.value.trim());
      const specialCharPassword = specialCharRegExp.test(event.target.value.trim());
      const minLengthPassword =   minLengthRegExp.test(event.target.value.trim());
      let errMsg ="";
      if(passwordLength===0){
              errMsg="Password is empty";
      }else if(!uppercasePassword){
              errMsg="At least one Uppercase";
      }else if(!lowercasePassword){
              errMsg="At least one Lowercase";
      }else if(!digitsPassword){
              errMsg="At least one digit";
      }else if(!specialCharPassword){
              errMsg="At least one Special Characters";
      }else if(!minLengthPassword){
              errMsg="At least minumum 8 characters";
      }else{
        errMsg=""
      }
      
      setPasswordErr(errMsg);
      }
      if(event.target.name==="confirm_password"){
          if(formData.password){
            if(formData.password !== event.target.value.trim()){
              setConfirmPasswordError("Confirm password is not matched");
            }
            else{
              setConfirmPasswordError("")
            }
          }
      }

      setFormData({
        ...formData,
        [event.target.name]: event.target.value.trim(),
      });
    
  };

  // user register funation
  const doUserSignUp = (e) => {
    e.preventDefault();
    var isValidate = false;
    const userObj = Object.values(formData).map((res, v) => {
      if (Object.values(formData)[v] === "") {
        setSubmitted(true);
        isValidate = true;
        return false;
      }
      return 0;
    });
    var user = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      date_of_birth: formData.dob,
      email: formData.email.trim(),
      password: formData.password.trim(),
      confirm_password: formData.confirm_password.trim(),
      gender: formData.gender,
      terms_confirmed: formData.terms_confirmed,
      username: formData.username,
    };

    if (!isValidate && formData.terms_confirmed) {
      setDisableSubmit(true);
      authServices.userSignUp(user).then(
        (data) => {
          handleLoginUserDireact();
        },
        (error) => {
          if (error.date_of_birth) {
            toast.error(`${error.date_of_birth[0]}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else if (error.email) {
            toast.error(error.email[0], {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else if (error.password) {
            toast.error(error.password[0], {
              position: toast.POSITION.TOP_RIGHT,
            });
          }else if (error.username) {
            toast.error(error.username[0], {
              position: toast.POSITION.TOP_RIGHT,
            });
          } 
           else {
            toast.error(error.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          setDisableSubmit(false);
        }
      );
    } else {
      if(!formData.terms_confirmed && isValidate){
        setIsError("All fields are required");
      }else{
      
        setIsError("Please agree to terms and conditions")
      }
     
    }
  };

  const handleLoginUserDireact = () => {
    var user = {
      email: formData.email,
      password: formData.password,
    };
    authServices.userLogin(user).then(
      (data) => {
        setisLogedIn(true);
        toast.success("Registered Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setDisableSubmit(false);
        localStorage.setItem("email", formData.email);
        localStorage.setItem(
          "fullName",
          `${data.data.first_name} ${data.data.last_name}`
        );
        localStorage.setItem("ssoToken", data.data.accessToken);
        localStorage.setItem("isLogin", true);
        localStorage.setItem("uid", data.data.uuid);
        localStorage.setItem(
          "fullName",
          `${data.data.first_name} ${data.data.last_name}`
        );
        props.history.push("/profile-setting");
   
      },
      (error) => {
        setDisableSubmit(false);
        toast.error("Email or Password is wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };

  const handleClickTearms = (e) => {
    setFormData({
      ...formData,
      terms_confirmed: !formData.terms_confirmed,
    });
    setIsError(false);
  };
  return (
    <div>
      <div className="right-sign-up-section">
        <Header as="h4" textAlign="center">
          No account? No problem,Jump on board in seconds.
        </Header>
        <Button className="primary-btn-login">Signup</Button> 
        <div className="sign-up-form">
          <Form>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="First Name"
                maxLength={30}
                placeholder="First Name"
                value={formData.firstName}
                name="firstName"
                required
                error={submitted && !formData.firstName ? true : false}
                onChange={handleInputChange}
              />
              <Form.Input
                fluid
               label="Last Name"
                maxLength={30}
                placeholder="Last Name"
                name="lastName"
                required
                value={formData.lastName}
                error={submitted && !formData.lastName ? true : false}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group widths="equal" style={{position:'relative'}}>
              <Form.Input
                fluid
                label="Email Address"
                placeholder="Email Address"
                value={formData.email}
                name="email"
                type="email"
                required
                onChange={handleInputChange}
                error={submitted && !formData.email ? true : false}
              />
            
                <Form.Input
                  label="Password"
                  className="my-input"
                  required
                  fluid
                  type={Show ? "password":"input"}
                  name="password"
                  minLength={8}
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleInputChange}
                  error={passwordError ? true:false}
                />
                <Icon
                  name={Show ? "eye slash outline" : "eye"}
                  onClick={() => setShow(!Show)}
                  className="eye-icon-crfpass"
                  
                />
                {passwordError &&<div className="pass-err-msg" >
                  {passwordError}
                </div>}
            
                <Form.Input
                  label="Confirm password"
                  className="my-input"
                  fluid
                  required
                  type={ShowConfirm ? "password":"input"}
                  name="confirm_password"
                  minLength={8}
                  placeholder="Confirm password"
                  value={formData.confirm_password}
                  onChange={handleInputChange}
                  error={confirmPasswordError ? true : false}
                />
                <Icon
                  name={ShowConfirm ? "eye slash outline" : "eye"}
                  onClick={() => setShowConfirm(!ShowConfirm)}
                  className="eye-icon-pass"
                
                />
                 {confirmPasswordError &&<div className="cnfpass-err-msg">
                  {confirmPasswordError}
                </div>}
              {/* </div> */}
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                   label="Date of birth"
                 placeholder="Date of birth"
                 required
                 type="date"
                name="dob"
                error={submitted && !formData.dob ? true : false}
                value={formData.dob}
                onChange={handleInputChange}
              />
              <Form.Select
                 label="Gender"
                fluid
                required
                options={options}
                placeholder="Gender"
                name="gender"
                error={submitted && !formData.gender ? true : false}
                onChange={(e, { value }) =>
                  setFormData({
                    ...formData,
                    gender: value,
                  })
                }
              />
               <Form.Input
                 label="User name"
                 required
                fluid
                placeholder="User name"
                value={formData.username}
                name="username"
                onChange={handleInputChange}
                error={submitted && !formData.username ? true : false}
              />
            </Form.Group>
            <div style={{ marginBottom: 25,textAlign:"center" }}>
              <span style={{marginBottom:"10px"}}>
                <span style={{ fontSize: 10 }}>
                  By ticking the box, you are agreeing with our{" "}
                </span>
                <br />
                <Link to="/terms-conditions">
                  <span className="link-span">Terms and conditions</span>
                </Link>
                <br />
                <Link to="/policy">
                  <span className="link-span">Policy and Data Use</span>
                </Link>
                <Link to="/community-guidelines">
                  <span className="link-span">Community guidelines</span>
                </Link>
                <Link to="/cookies">
                  <span className="link-span">Cookie use</span>
                </Link>
              </span>
              <Form.Checkbox
             required
             label="Agree & Create Account"
             checked={formData.terms_confirmed}
             onChange={handleClickTearms}
           />
            </div>

          
            <div style={{ color: "#ff4040" }}>{isError ? isError : null}</div>
            <Button
              //   onClick={() => props.history.push("/profile")}
              className="primary-btn-login"
              onClick={doUserSignUp}
              disabled={disableSubmit}
            >
              Confirm & Sign up
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}
export default withRouter(SignUp);
