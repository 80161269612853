import React, { useState, useEffect, useContext } from "react";
import { NotificationContext } from "../../../context/NotificationContext";
import "./notification.scss";
import { useHistory } from "react-router";
import moment from "moment";

import "semantic-ui-css/semantic.min.css";
// import "../../Login/Login.scss";
import { Header, Image } from "semantic-ui-react";

import "../../../App.scss";
import Footer from "../Footer";
import "./about-us.scss";
import "./notification.scss";
import LogoHeader from "./LogoHeader";
import { NotificationServices } from "../../../Services/NotificationServices";
function Notifications() {
  const { Notification,setNotification } = useContext(NotificationContext);
  const [StoredNotifications, setStoredNotifications] = useState([]);

  const notificationsServices = new NotificationServices();

  const history = useHistory();

  useEffect(() => {
    getNotifications();
  }, []);
  useEffect(() => {
  console.log("Notification",Notification)
  
  }, [Notification])

  const getNotifications = () => {
    notificationsServices
      .getAllNotifications()
      .then((data) => {
        //setStoredNotifications(data);
        let newArray = [];
        for (let i = data?.length - 1; i >= 1; i--) {
          newArray.push(data[i]);
        }
        setStoredNotifications(newArray);
      })

      .catch((err) => {});
  };

  const handleNotificationClick = (noti) => {
    if (noti.chat_room) {
      history.push({
        pathname: "/chat",
        state: {
          // location state
          chat_room: noti.chat_room,
          sender_image: noti.sender_image,
        },
      });
    } else if (noti.comment) {
      //history.push(`/post/${noti.comment.post_id}`);
      history.push({
        pathname: `/post/${noti.comment.post_id}`,
        state: {
          // location state
          comment: noti.comment,
        },
      });
    } else if (noti.favourite) {
      history.push(`/post/${noti.favourite.post_id}`);
    } else if (noti.follow) {
      history.push(`/followers`);
    } else if (noti.follow_request) {
      history.push(`/friends`);
    } else if (noti.reaction) {
      history.push(`/post/${noti.reaction.post_id}`);
    }
    //history.push(`/post/${}`);
  };


  return (
    <>
      <div className="login-page" style={{ marginBottom: "20%" }}>
        <LogoHeader />
        <div className="main-content">
          <h2
            style={{
              fontWeight: 600,
              color: "#000",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Notifications
          </h2>
          {/* {Notification.map((item) => (
          <div>{item.message}</div>
        ))}
        <h2>edeerg </h2> */}
          <div className="notification-contant">
            {Notification && Notification.length > 0 ? (
              Notification.map((noti, idx) => (
                <div
                  key={idx}
                  className="div-inline"
                  style={{ marginBottom: 15, display: "flex" }}
                >
                  <div
                    className="div-inline"
                    style={{
                      width: "70%",
                      display: "flex",
                      cursor: "pointer",
                    }}
                    onClick={() => handleNotificationClick(noti)}
                  >
                    <div className="img-block-user">
                      <div className="div-inline img-div">
                        {/* <span className="notification-icon"></span> */}
                        <Image
                          src={
                            noti.sender_image
                              ? noti.sender_image
                              : "https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"
                          }
                          alt="image not found"
                          width="100"
                          height="100"
                          style={{ borderRadius: "50px" }}
                        />
                      </div>
                    </div>
                    <p className="div-inline text-block-user">
                      @{noti.message}
                    </p>
                  </div>
                  <div className="div-inline" style={{ width: "30%" }}>
                    {moment
                      .utc(noti.datetime)
                      .local()
                      .format("DD/MM/YYYY hh:mm a")}
                  </div>
                </div>
              ))
            ) : (
              null
            )}

            {StoredNotifications && StoredNotifications.length > 0 ? (
              StoredNotifications.map((noti, idx) => {
                return (
                  <div
                   key={idx}
                    className="div-inline"
                    style={{ marginBottom: 15, display: "flex" }}
                    onClick={() => handleNotificationClick(noti)}
                  >
                    <div
                      className="div-inline"
                      style={{
                        width: "70%",
                        display: "flex",
                        cursor: "pointer",
                      }}
                    >
                      <div className="img-block-user">
                        <div className="div-inline img-div">
                          <Image
                            src={
                              noti.sender_image
                                ? noti.sender_image
                                : "https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"
                            }
                            alt="image not found"
                            width="100"
                            height="100"
                            style={{ borderRadius: "50px" }}
                          />
                        </div>
                      </div>
                      <p className="div-inline text-block-user">
                        @{noti.message}
                      </p>
                    </div>
                    <div className="div-inline" style={{ width: "30%" }}>
                      <div>
                        {moment
                          .utc(noti.datetime)
                          .local()
                          .format("DD/MM/YYYY hh:mm a")}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>
                <Header as="h3" textAlign="center"></Header>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Notifications;
