import React, { useState, useEffect, useContext } from "react";
import "semantic-ui-css/semantic.min.css";
// import "../../Login/Login.scss";
import { Segment, Menu, Image, Input, Popup, Header } from "semantic-ui-react";
import { Icon } from "semantic-ui-react";
import "../../App.scss";
import "../FooterLinks/AboutUs/about-us.scss";
import "./home.scss";
import { toast } from "react-toastify";
import { Link ,useHistory} from "react-router-dom";
import MenuBar from "../FooterLinks/AboutUs/MenuBar";
import { UserProfileServices } from "../../Services/UserProfileServices";
import noRecordUser from "../../assets/img/no-result.png";
import defaultUserImage from "../../assets/img/user-image.png";
import { DmsContext, NotificationContext } from "../../context/NotificationContext";
import { HomeServices } from "../../Services/HomeServices";
const userProfileServices = new UserProfileServices();
const homeServices=new HomeServices()


export default function HeaderHome(props) {
  const [activeItem, setActiveItem] = useState("home");
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [searchUserAllList, setSearchUserAllList] = useState([]);
  const [isOpenUserList, setIsOpenUserList] = useState(false);
  const {  CountNotification,} = useContext(NotificationContext);
  const {DmsCount} =useContext(DmsContext);
  
  
  const  history=useHistory()
  useEffect(() => {}, []);
  
  const handleItemClick = (e, { name }) => {
    setActiveItem(name); 
  };

  const handleSearchInput = (e) => {
    setSearchVal(e.target.value);
    getFilterAllUserListHeader(e.target.value);
  };

  const getFilterAllUserListHeader = (searchVal = "") => {
    userProfileServices.getFilterAllUserListHeader(searchVal).then(
      (data) => {
        setIsOpenUserList(true);
        setSearchUserAllList(data);
      },
      (error) => {
    
      }
    );
  };

  

  const handleProfileOpen=(id)=>{
    history.push(`/user-profile/${id}`)
  }

  const handleClickFollowingUser = (id) => {
    let uid = localStorage.getItem("uid");
    let payload = {
      receiver: id,
      sender: uid,
    };
    if (id !== uid) {
      homeServices.postFriendRequest(payload).then((data) => {
  
        if (data.message) {
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (data.response) {
          toast.error(data.response, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    } else {
      toast.error("cant send request to yourself", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    //setIsLoading(true);
    // handleClickToSeePost(id)
  };
  return (
    <div className="login-page">
      <div className="about-us-header">
        <div className="div-inline image-header-home">
          <Image
            src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1601659221875x962391620398017200%2Flogo-02.png?w=256&h=108&auto=compress&fit=crop&dpr=1"
            width="210"
            height="80"
            className="img-header"
          />
        </div>
        <div className="div-inline header-nav">
          <Segment inverted>
            <Menu inverted secondary>
              <Menu.Item
                name="home"
                active={activeItem === "home"}
                onClick={handleItemClick}
                as={Link}
                to="/public-reactions"
              >
                <div>
                  <Icon name="home" />
                  <span>Home</span>
                </div>
              </Menu.Item>
              <Menu.Item
                name="notification"
                active={activeItem === "notification"}
                onClick={handleItemClick}
                as={Link}
                to="/notifications"
                style={{padding:'3px'}}
              >
                <div >
                  <Icon name="bell" />
                  <span>
                    Notifications
                    <span className="notification">{CountNotification}</span>
                  </span>
                </div>
              </Menu.Item>
              <Menu.Item
                name="DM"
                active={activeItem === "DM"}
                onClick={handleItemClick}
                as={Link}
                to="/chat"
              >
                <div>
                  <Icon name="mail" />
                  <span>
                    DMs
                    <span className="notification">{DmsCount}</span>
                  </span>
                </div>
              </Menu.Item>
            </Menu>
          </Segment>
        </div>
        <div className="div-inline search-header-home">
          <Popup
            trigger={
              <Input
                fluid
                type="text"
                onChange={handleSearchInput}
                placeholder="Search User"
                name="searchVal"
                value={searchVal}
              />
            }
            on="click"
            position="bottom center"
          >
            <div className="popup-search-user-content">
              {searchVal && searchUserAllList.length > 0 ? (
                searchUserAllList.map((item) => {
                  return (
                    <>
                      <div className="pop-up-search-user-header">
                        <div className="search-header-all-user"  style={{cursor:"pointer"}}>
                         <div onClick={()=>handleProfileOpen(item.uuid)} style={{display:'flex',alignItems:"center"}}>
                          <Image
                            src={
                              item.photo_url ? item.photo_url : defaultUserImage
                            }
                            width="50"
                            height="50"
                            className="image-user-search"
                          />
                          <Header as="h5" style={{ margin: 0 }}>
                            {item.first_name}
                            {item.last_name}
                            <Header.Subheader>{item.username}</Header.Subheader>
                          </Header>
                          </div>
                          <div className="follow-icon-add">
                            <Icon
                              name="add user"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleClickFollowingUser(item.uuid)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <div>
                  <Image src={noRecordUser} width="250" height="200" />
                </div>
              )}
            </div>
          </Popup>
        </div>
        <div className="div-inline image-menu-bar-home">
          <Icon
            name="bars"
            size="big"
            style={{ color: "#fff" }}
            onClick={() => setIsOpenMenu(!isOpenMenu)}
          />
          {isOpenMenu && <MenuBar />}
        </div>
      </div>
    </div>
  );
}
